@import './bootstrap_colors.scss';
@import "./login.scss";
@import './sidebar.scss';
@import './header.scss';
@import './breadcrumb.scss';
@import './homepage.scss';
@import './inviti.scss';
@import './autorizzazioni.scss';
@import "./download.scss";

@font-face {
    font-family: 'Cairo-Semibold';
    src: url('./font/Cairo-SemiBold.ttf');
    font-weight: normal;
    font-style: normal;
}

body {
  color: rgba(0, 0, 0, 0.65);
  font-family: 'Roboto', sans-serif;
  background-color: #f2f5f8 !important;
}
a {
  color: #0091dc
}

.app, .app-dashboard, .app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-header.navbar { display: flex; }
.app-header * a { color: #fff; }
.app-header * a:hover { text-decoration: none; color:rgb(0, 153, 255); }
.app-header.navbar .navbar-brand { text-align: left; font-size: 26px; flex:2; padding: 15px 0 0 0; vertical-align: middle; line-height: 24px; color:#fff; }
.sidebar-fixed .sidebar {height: 100vh;}
.header-fixed .app-body {
  margin-top:64px;
  margin-bottom: 0;
}
.header-fixed .app-header {
  position: fixed;
  z-index: 1020;
  width: 100%;
}

.app-body {
  .app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden; }
  .app-body .main {
    flex: 1;
    min-width: 0;
  }
}

.card-body, .card-block {
  padding: 15px; }

.navbar {
  align-items: unset;
}

.breadcrumb-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991.98px) {
  .app-footer, .main {
    margin-left: 0!important;
  }
}

.container-fluid {
  margin-top: 55px !important;
  padding: 0;
  width: 100%;
}
  @media (max-width: 768px) {
  .container-fluid {
    margin-top: 10px !important;
    padding: 0 12px !important;
  }
}

.sidebar-minimized.sidebar-fixed .main, .sidebar-minimized.sidebar-fixed .app-footer {
  margin-left: 64px;
}

.sidebar-fixed .main, .sidebar-minimized.sidebar-fixed .app-footer {
  margin-left: 220px;
}

.aside-menu {
  transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
}

.btn-primary-viola {
  border-radius: 4px;
  width: 100px;
  background-color: #415ca3;
  border: 1px solid #415ca3;
  padding: 8px;
  font-size: 14px;
  transition: opacity .15s ease-in-out;
  &:hover {
    opacity: 0.8;
    background-color: #415ca3;
    border: 1px solid #415ca3;
  }
  &:active {
    background-color: #415ca3 !important;
    border: 1px solid #415ca3 !important;
  }
  &:focus {
    box-shadow: 0 0 0 0 !important;
  }
}

.btn-secondario {
  background-color: #ffffff;
  border: 1px solid #415ca3;
  color: #415ca3;
  border-radius: 4px;
  height: 32px;
  padding-top: 4px;
  &:hover {
    opacity: 0.8;
    background-color: #ffffff;
    border: 1px solid #415ca3;
    color: #415ca3;
  }
}

.btn-secondario-danger {
  background-color: #ffffff;
  border: none;
  color: #da5848;
  height: 32px;
  &.small {
    height: 28px;
    padding: 0px;
    font-size: 13px;
  }
  &:hover {
    opacity: 0.8;
    background-color: #ffffff;
    border: none;
    color: #da5848;
  }
  &:active {
    color: inherit !important;
  }
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.btn-modifica {
  background-color: #ffffff;
  border: none;
  color: rgb(0, 144, 209);
  border-radius: 4px;
  height: 32px;
  &:hover {
    opacity: 0.8;
    background-color: #ffffff;
    border: none;
    color: #415ca3;
  } &:focus {
    color: rgb(0, 144, 209);
    background: inherit;
    outline: none;
    box-shadow: none !important;
  }
  &:active {
    color: #415ca3;
  }
  &:disabled {
    background-color: #ffffff;
    border: none;
    color: #D9D9D9;
    border-radius: 4px;
    height: 32px;
  }
}

.btn-primary {
  background: linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
  border-radius: 2px;
  border: 0;
}
.btn-secondary {
  border-radius: 2px;
}
.btn-annulla {
  background: rgb(242, 245, 248);
  color: rgb(0, 144, 209);
  border: 1px solid rgb(0, 144, 209);
  &:hover {
    border: 1px solid rgb(0, 144, 209);
    color: rgb(0, 144, 209);
  }
}

.card-container {
  transition: all .3s ease-in-out;
  box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
  /* padding:3px 0; */
  background:#fff;
  margin: 12px 0px;

  .card-body {
    padding: 24px;
  }


  @media(min-width: 768px) {
    margin: 0;
    &:hover{
      transition: all .3s ease-in-out;
      .but-kpi-dettagli {
        transition: all .3s ease-in-out;
        opacity: 1;
      }
    }
  }

  @media only screen
    and (min-device-width : 768px)
    and (max-device-width : 1024px)  {     margin: 12px 0; }

  .but-kpi-dettagli {
    transition: all .3s ease-in-out;

    @media (min-width: 768px) {
      opacity: 0;
    }
    .btn-secondary {
      background-color: #ffffff;
      color: rgb(0, 144, 209);
      border: none;
      &:focus {
        box-shadow: none !important;
        outline: none !important;
      }
    }
  }
}
.nav-tabs {
  border: 1px solid rgb(201, 217, 232);
  background-color:#fff!important;
  .nav-item a.nav-link {
    cursor: pointer;
    padding:15px!important;
    border-bottom:5px solid #fff!important;
    background:#fff;
    border-radius: 0!important;
  }
  .nav-item a.nav-link.active {
    background-color:rgb(242, 245, 248) !important;
    border-bottom:5px solid #0090d1!important;
    color:rgb(0, 144, 209)!important;
    &:hover {
      background: rgb(242, 245, 248)!important;
    }
  }
  .nav-item a.nav-link:hover {
    background-color:#fff!important;
    color:inherit!important;
  }
  .nav-item {
    width:30%;
    text-align:center;
    margin-bottom:0;
  }
}
.custom-checkbox .custom-control-label::before {
  border-radius: 2px;
  border-color: #0090d1;
}
.modal-header {
  border-bottom: 1px solid #0090d1;
  color: rgb(0, 80, 117);
  font-family: 'Cairo-SemiBold';
  font-size: 24px;
  .close {
    color: rgb(0, 144, 209);
    font-size: 30px;
    opacity: 1;
  }
}
.modal-footer {
  background:rgb(242, 245, 248);
}
.label-form-inviti {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
}
.modal-body {
  .form-group {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
  }
}
.div-numero-licenze {
  .dropdown-item {
    padding: 0 10px;
  }
  .dropdown-menu.show .dropdown-item:not(:last-child) {
    width: 100%;
  }
  .dropdown-menu.show .dropdown-item {
    span:nth-child(1) {
      display: block;
      float: left;
    }
    span:nth-child(2) {
      display: block;
      float: right;
    }
  }
}
.input-cerca {
  display:inline;
  width:80%;
  padding: 18px 10px;
  font-size: 14px;
  background: rgb(242, 245, 248);
  color: rgb(193, 193, 196);
  &:focus {
    color: inherit;
    box-shadow:none;
  }
}
.dropdown-licenze .dropdown-item {
  width: 270px;
}


.container-wip {
  display: 1 1 auto;
  text-align:-webkit-center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  @media(max-width: 767px) {
    width: 80%;
  }
    padding: 32px;
    background-color: white;
    border-radius: 0px;
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.64);

}

.div-img-wip {
  display: inline-block;
  vertical-align: top;
  // background-color: rgb(59, 86, 153);
  width: 100%;
  text-align: center;
  margin: 3% 0;
}

.img-wip {
  max-width: 100%;
  height: auto;
  width: 220px;
}

.img-wip-secondary {
  width: 180px;
  margin-bottom: 5%;
}

.div-text-wip {
  text-align: center;
  margin:auto;
  max-width: 775px;
  font-size: 35px;
  // background-color: rgb(59, 86, 153);
  padding: 70px 45px 30px 45px;
  font-weight: 700px;
  color: #eeeff4 !important;
  margin-top: -3px;
  letter-spacing: normal;
  border-radius: 0px 0px 5px 5px;
  @media(max-width: 768px) {
    font-size: 24px;
    border-radius: 0px 0px 8px 8px;
  }
}

.div-text-errorpage {
  text-align: center;
  margin:auto;
  max-width: 775px;
  font-size: 20px;
  padding: 25px;
  margin-top: -3px;
  letter-spacing: -0.5px;
  border-radius: 0px 0px 5px 5px;
  @media(max-width: 768px) {
    font-size: 24px;
    border-radius: 0px 0px 8px 8px;
  }
}

.div-bottoni-errorpage {
  padding-top:40px;
  color: #0090d1 !important;
  font-size: 18px;
  font-weight: bold;
  .btn-redirect-login {
    @media (max-width: 768px) {
      font-size: 16px;
    }
    margin-right: 15px;
    background-color: #ffffff;
    border-radius:4px;
    border: 1px solid #415ca3;
    width: 120px;
    height: 45px;
    font-weight: 600;
    &:hover {
      opacity: 0.8;
    }
    &:active{
      background-color: #ffffff !important;
      border-radius:4px !important;
      color: #415ca3 !important;
    }
  }

  p {
    cursor: pointer;
  }

  .btn-contact-us {
    margin-left:15px;
    background-color: #ffffff;
    border-radius:4px;
    border: 1px solid #415ca3;
    color: #415ca3;
    width: 120px;
    height: 45px;
    font-weight: 600;
    &:hover {
      opacity: 0.8;
    }
    &:active{
      background-color: #ffffff !important;
      border-radius:4px !important;
      color: #415ca3 !important;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
    font-size: 14px;
  }
}

#invitation-accept {
  width: 40%;
  background: #fff;
  margin: 0 auto;
  text-align: left;
  margin-top: 80px;
  .div-img-wip {
    text-align: left;
    padding: 80px;
    margin: 0;

    img {
      margin-bottom: 40px;
      object-position: -40px;
      width: 300px;
    }

    h2 {
      font-family: 'Cairo-SemiBold';
      font-size: 24px;
      color: rgb(0, 80, 117);
    }

    p {
      font-size: 16px;
    }

    button {
      background: linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
      border-radius: 2px;
      height: 48px;
      width: 100%;
      color: rgb(255, 255, 255);
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      border: none;
    }
    button.secondary {
      height: 48px;
      background: #fff;
      border: 1px solid rgb(0, 144, 209);
      border-radius: 2px;
      color: rgb(0, 144, 209);
    }

    p.mid-line {
      width: 100%; 
      text-align: center; 
      border-bottom: 1px solid rgb(90, 104, 114);
      line-height: 0.1em;
      margin: 40px 0; 
      color: rgb(90, 104, 114);
    } 
   
    p.mid-line span { 
      padding: 20px 0;
       background:#fff; 
       padding:0 10px; 
    }
  }
  
}


.p-top-20 {
  padding-top: 20px;
}