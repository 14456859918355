#page-gestione-utenti {
  .nav-link {
    font-family: 'Cairo-SemiBold';
    padding: 0 15px;
    font-size: 24px;
    border: none;
  }
  .tab-content {
    border: none;
    background-color: #fff!important;
    padding: 20px;
  }
  .div-licenze {
    // background: rgba(250, 250, 250, 1);
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
    border-radius: 0;
    font-family: Cairo-SemiBold;
    color: rgb(0, 80, 117);
    font-size: 24px;
  }
  .btn-primary {
    padding: 8px 12px;
    &:hover {
      opacity: 0.8;
      background-color: #415ca3;
    }
    &:focus {
      box-shadow: 0 0 0 0;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
    font-size: 14px;
  }
}

.div-licenze {
  line-height: 47px;
  padding-left: 10px;
  font-size: 16px;
  @media(max-width: 768px) {
    padding: 0 5px;
    line-height: 15px;
  }
}

.div-numero-licenze {

  padding:0;
  float: right;
  font-size: 24px;
  span b {
    padding-right: 15px;
  }
  @media(max-width: 768px) {
    font-size: 15px;
  }
}

.riga-ricerca-utente {
  margin-top: 15px;
  @media(max-width: 768px) {
    margin-top: 20px;
  }
}

.riga-ricerca-utente-inviti {
  margin-top: 20px;
  max-width: 398px;
}
.riga-utenti {
  margin-top: 25px;
  // flex: 1 1 auto;
  align-items:center;
  @media(max-width:768px) {
    padding: 0px;
  }
}

div.riga-console-utenti > * {
  @media(max-width:768px) {
    padding: 0px;
  }
}

.riga-licenze {
  height: 40px;
  // margin-top: 25px;
  margin-bottom: 55px;
  @media(max-width:768px) {
    height: 0px;
  }
}

.riga-console-tabella {
  margin-top: 20px;
  flex: 1 1 auto;
  @media(max-width:768px) {
    padding: 0px;
  }
}

.colonna-console-tabella {
  @media(max-width:768px) {
    padding: 0px;
  }
}

.label-form {
  display: inline-block;
  padding-right: 0.3rem;
  // margin-left: 25px;
}

.form-inline .form-group {
  @media (min-width: 576px) {
    display: inline;
  }
}

.form-inline label {
  @media (min-width: 576px) {
    display: inherit;
  }
}

.input-cerca-inviti {
  max-width: 400px;
}

.btn-form-blue {
  min-width: 150px;
  &:disabled{
    background-color: #ccc !important;
    border-color: #ccc !important;
  }
}

.btn-form-blue-cerca {
  margin-left: 10px;
}

.table {

}
.table tr {
  line-height: 35px;
  @media (max-width: 1200px) {
    th {
      font-size: 12px;
      padding: 2px;
    }
  }
}

.table thead th {
  border: 0px;
  font-size: 12px;
  font-weight: 700;
  background-color: #fff;
  border-bottom: 1px solid rgb(157, 166, 173);
  color: rgb(0, 80, 117);
  @media(min-width: 1200px) {
    font-size: 15px;
  }
}

.table th:nth-child(1) {
  width: 15px;
}

.table th:nth-child(8) {
  width: 155px;
}

.table td, .table th {
  border: 1px;
  font-weight: 400;
  font-size: 12px;
  border-bottom: 1px solid rgb(157, 166, 173);
  @media(min-width: 1200px) {
    font-size: 15px;
  }
}

.col-button {
  float: right;
}

.label-form {
  display: inline-block;
  padding-right: 0.3rem;
  // margin-left: 25px;
}

.label-form-inviti {
  display: inline-block;
  padding-right: 0.3rem;
  // margin-left: 25px;
  @media(max-width: 576px) {
  }
}

.form-inline .form-group {
  @media (min-width: 576px) {
    display: inline;
  }
}

.form-inline label {
  @media (min-width: 576px) {
    display: inherit;
  }
}

.btn-form-aggiungi {
  border-radius: 5px;
  background-color: #fff;
  border: 1px #fff;
  margin: auto;
  // min-width: 75px;
  width: 100%;
  border: 1px dotted #000;
  font-size: 14px;
  color: #000;
  &:hover {
    color:#000;
  }
  &:active {
    color:#000;
    border: 1px dotted #000 !important;
  }
  &:focus {
    box-shadow: none !important;
  }
  &.disabled{
    background-color: #fff;
    border: 1px dotted rgba(#000, .3);
    color: rgba(#000, .3);
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.btn-form-aggiungi:active, .btn-form-aggiungi.active {
  border-radius: 5px;
  background-color: #fff;
  border: 1px #fff;
  min-width: 75px;
  border: 1px dotted #000;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  font-size: 14px;
}

.btn-form-aggiungi:hover, .btn-form-aggiungi.hover {
  border-radius: 5px;
  background-color: #fff;
  border: 1px #fff;
  min-width: 75px;
  border: 1px dotted #000;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  font-size: 14px;
}

.input-form {
  border-radius: 5px;
  // min-width: 250px;
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  margin-left: 25px;
  &.error {
    border: 1px solid red;
  }
}

.input-form-nuovo {
  border-radius: 5px;
  // min-width: 250px;
  margin-left: 5px;
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.form-inline .form-control {
  width: auto;
}

// .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
//   background-color: #fff !important;
//   color: #000 !important;
//   border: none;
// }

.dropdown-licenze {
  height: 47px;
  padding-top:0;
  top: 0;
  color: rgb(0, 80, 117);
  background: rgb(242, 245, 248);
  vertical-align: top;
  & button {
    border: none;
    background-color: #fff;

    color: #000;
    &:focus {
      box-shadow: none !important;
    }
    &:hover {
      background-color: #fff;
      color: #000;
      &:active {
        background-color: #fff;
        color: #000;
      }
        &:focus {
    box-shadow: none !important;
  }
    }
  }
}

  .btn-form-primary {
    background-color: #415ca3;
    border: 1px solid #415ca3;
    padding: 8px 12px;
    &:hover {
      opacity: 0.8;
      background-color: #415ca3;
    }
    &:active {
      opacity: 0.8;
      background-color: #415ca3 !important;
    }
  &:focus {
    box-shadow: none !important;
  }
    @media (max-width: 768px) {
      font-size: 16px;
    }
    font-size: 14px;
  }

  .btn-form-secondary {
    background-color: #fff;
    border: 1px solid rgba(204, 204, 204, 1);
    padding: 8px 12px;
    color:#797979;
    &:hover {
      color:#797979;
      opacity: 0.8;
      background-color: #fff;
      border: 1px solid rgba(204, 204, 204, 1);
    }
    &:active {
      border: 1px solid #415ca3 !important;
      &:focus {
        box-shadow: none !important;
      }
    }
    &:focus {
      box-shadow: none !important;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
    font-size: 14px;
  }

  .single-tag-blue {
    border-radius: 5px;
    left: 0px;
    top: 0px;
    width: 150px;
    height: 20px;
    background: #fff;
    border: 1px solid rgb(13, 108, 128);
    font-family: Cairo-SemiBold;
    color: rgb(13, 108, 128);
    font-size: 12px;
    padding:0 5px;
  }

  .single-tag-red {
    border-radius: 5px;
    left: 0px;
    top: 0px;
    width: 150px;
    height: 20px;
    background: inherit;
    border: 1px solid rgb(197, 48, 144);
    font-family: Cairo-SemiBold;
    font-size: 12px;
    color: rgb(197, 48, 144);
    padding:0 5px;
  }

  .single-tag-green {
    border-radius: 5px;
    left: 0px;
    top: 0px;
    width: 150px;
    height: 20px;
    background: inherit;
    border: 1px solid rgb(48, 197, 63);
    font-family: Cairo-SemiBold;
    font-size: 12px;
    color: rgb(48, 197, 63);
    padding:0 5px;
  }

  .single-tag-violet {
    border-radius: 5px;
    left: 0px;
    top: 0px;
    width: 150px;
    height: 20px;
    background: inherit;
    border: 1px solid rgb(119, 69, 236);
    font-family: Cairo-SemiBold;
    font-size: 12px;
    color: rgb(119, 69, 236);
    padding:0 5px;
  }



//// modale inviti

.modal-dialog{
  max-width: 734px !important;

  .form-inviti {
    line-height: 35px;
    .col-sm-12{
      padding: 20px 15px
    }

    .form-group{
      label{

      }
      .input-form{
        margin: 0;
        width: calc(100% - 84px);
      }
      .custom-switch{
        display: inline-block;
        padding: 0;
        label{
          display: inline-block;
          text-align: inherit;
          width: auto;
          padding-right: 0;
          margin-right: 0;
          margin-left: 37px;
        }
      }
      .switch-component{
        vertical-align: middle;
        input[type="checkbox"]{
          display: none;
        }
      }
      .switch-component-table {
        vertical-align: middle;
        input[type="checkbox"]{
          display: none;
        }
        margin-top: 10px !important;
      }
    }
  }
}

.dropdown-filtro {
  margin-left: 5px;
  font-size: 10px;
  button {
    background-color: #fff !important;
    border: none;
    padding: 0px;
    font-size: 12px;
    color: gray;
    &:hover {
      color:#000;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
}

.item-filtro {
  display: inline-flex;
  margin: 0 10px;
  line-height: 28px;
  .opzione-filtro {
    float: right;
  }
  &:focus {
    box-shadow: none;
    // outline: none;
  }
}

.drop-menu-controls {
  width: 100%;
  padding: 10px;
  display: inline-flex;
  justify-content: space-between;
  button {
    color: #435CA3;
  }
}
.icona-filtro {
  margin-left: 5px;
  font-size: 12px;
  color: rgb(0, 80, 117);
  &:hover {
    color:#000;
  }
}

.drop-item {
  border: none;
  &:active {
    border: none !important;
    color: unset !important;
  }
  &:not(:last-child){
    border: none !important;
  }
}

.dropmenu-filtro {
  padding: 0 1px;
}

.error-message {
  color: red;
  font-size: 10px;
  padding-right: 5px;
}

.error-message-invited{
  color: red;
  font-size: 10px;
  padding-right: 5px;
  font-weight: bold;
}

.more-solutions {
  width: 30px;
  margin-left: 5px;
  height: 25px;
  background: inherit;
  background-color: rgba(67, 92, 163, 0.0980392156862745);
  font-size: 12px;
  color: #435CA3;
  padding:5px;
  border: none;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  &:hover {
  background-color: rgba(67, 92, 163, 0.0980392156862745);
  }
}

.drop-item-solutions {
  height: 40px;
  .dropdown-item {
    padding: 0 !important;
  }
}

.col-interfaccia {
  @media(max-width: 701px) {
    max-width: 30vw !important;
  }
  @media(min-width: 991) {
    max-width: 90vh !important;
  }
}

.col-vuota {
  @media(max-width: 701px) {
    max-width: 30vw;
  }
  @media(min-width: 991) {
    max-width: 50vh;
  }
}

.div-btn-right {
  float: right;
}

.modal-riepilogo-intestazione {
    margin: 10px 0 20px 0;
}

.scrollable-body {
    max-height: 200px;
    overflow-x: auto;
    overflow-x: hidden;
    margin-bottom: 20px;
}

.div-riepilogo-invito {
    border-bottom: 1px solid #d9d9d9;
    & div {
        margin: 0 5px;
        display: inline-flex;
        width: 100%;
        & span:nth-child(1) {
            width: 40%;
        }
        & span:nth-child(2) {
            width: 60%;
        }
    }
}

.collapsible-baseurl {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    margin-top: 30px;
    background-color: rgba(217, 217, 217, 0.247058823529412);
    width: 100%;
    & .collapsed {
        margin: 10px 20px;
    }
    & .card {
        border: none;
    }
}

.input-base-url {
    border-radius: 5px !important;
}

.drop-toggle-baseurl {
    margin-left: 1px;
    padding: 8px 12px !important;
    border-radius: 2px;
    background-color: #415ca3 !important;

    &:hover {
      background-color: #415ca3 !important;
    }
    &:active {
      background-color: #415ca3 !important;

    }
    &:focus {
        box-shadow: none !important;
    }
}

.popover.show.bs-popover-top {
    width: 9150px;
}

.controls-pop-env {
  margin: 20px 0;
  display: inline-flex;
  float: right;
  justify-content: space-between;
  & button:nth-child(1) {
      margin-right: 15px;
      height: 30px !important;
      padding: 0px 25px !important;
      opacity: 0.8;
      &:hover {
      opacity: 0.8 !important;
      background-color: #fff;
      }
      &:active {
      opacity: 0.8 !important;
      background-color: #fff;
      }
      &:focus {
          box-shadow: none !important;
      }
  }
  & button:nth-child(2) {
      color: #ffffff;
      height: 30px !important;
      padding: 0px 25px !important;
      background-color: #415ca3 !important;
      &:hover {
      opacity: 0.8 !important;
      background-color: #415ca3 !important;
      border: 1px solid #415ca3 !important;
      }
      &:active {
      opacity: 0.8 !important;
      background-color: #415ca3 !important;
      border: 1px solid #415ca3 !important;
      }
      &:focus {
          box-shadow: none !important;
      }
  }
}
.switch-component {
  margin: 0;
  .react-switch-bg * svg {
    display: none;
  }
}
.switch-component-table {
  margin: 10px 0 0 0;
  .react-switch-bg * svg {
    display: none;
  }
}

input.form-control {
  border:1px solid rgb(201, 217, 232);
  border-radius: 0;
}
