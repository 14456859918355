.logo-login {
  max-width: 340px;
}
@media (max-width: 768px) {
  .logo-login {
    max-width: 200px;
  }
}

.btn-login {
  background-color: #415ca3;
  color: #ffffff;
  border-radius: 4px;
  border: 1px;
  height: 48px;
  padding: 12px 20px;
  margin: 20px 0;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
.btn-login:hover {
  opacity: 0.8;
  background-color: #415ca3;
}
.btn-login:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}
.btn-login:active {
  background-color: #415ca3 !important;
  color: #ffffff !important;
}
@media (max-width: 768px) {
  .btn-login {
    font-size: 16px;
  }
}

.btn-registra {
  padding: 12px 20px;
  width: 100%;
  color: #0090d1;
  background-color: #ffffff;
  border: 1px solid #0090d1;
  border-radius: 2px;
  height: 48px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
@media (max-width: 768px) {
  .btn-registra {
    padding: 5px 20px;
    height: 35px;
  }
}
.btn-registra:hover {
  opacity: 0.8;
  color: #0090d1;
  border: 1px solid #0090d1;
  background-color: #ffffff;
}
.btn-registra:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}
.btn-registra:active {
  color: #0090d1 !important;
  background-color: #ffffff !important;
  border: 1px solid #0090d1;
}
@media (max-width: 768px) {
  .btn-registra {
    font-size: 16px;
  }
}

.loading-login {
  text-align: center;
  font-size: 20px;
  flex: 1 1 auto;
  width: 100%;
  padding: 25px;
}

.login-card-container {
  text-align: left !important;
  padding: 32px;
  background-color: rgb(255, 255, 255);
  border-radius: 0px;
  box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.64);
}
@media (max-width: 768px) {
  .login-card-container {
    height: 100vh;
    overflow: hidden;
  }
}
.login-card-container img {
  width: 200px;
}
@media (max-width: 768px) {
  .login-card-container img {
    width: 150px;
  }
}
.login-card-container label {
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
}
@media (max-width: 768px) {
  .login-card-container label {
    font-size: 16px;
    margin-top: 10px;
  }
}
.login-card-container input {
  height: 40px;
}
@media (max-width: 768px) {
  .login-card-container input {
    height: 30px;
  }
}
.login-card-container .login-card-header {
  padding: 15px 0;
}
.login-card-container .login-card-header div {
  float: left;
  margin-bottom: 20px;
  width: 100%;
}
.login-card-container .lost-password-container {
  position: "relative";
}
.login-card-container .login-link-container {
  text-align: center;
}
.login-card-container .login-link-container .link-login-password {
  color: #0091dc;
  padding: 6px 0px 6px 0px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
@media (max-width: 768px) {
  .login-card-container .login-link-container .link-login-password {
    font-size: 13px;
  }
}
.login-card-container .register-container {
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  padding: 20px 0;
}
@media (max-width: 768px) {
  .login-card-container .register-container {
    padding: 10px 0;
    font-size: 13px;
  }
}
.login-card-container .reg-link {
  color: #0091dc;
}

.login-container {
  padding: 0;
  width: 430px;
  margin-top: 40px;
}
@media (max-width: 768px) {
  .login-container {
    width: 100vw;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
  }
}

.col-login {
  text-align: -webkit-center;
}
.footer-store-links {
  position: fixed;
  bottom: 0;
  display: inline-flex;
  width: 100vw;
  background-color: rgb(228, 234, 240);
  height: 72px;
  align-items: center;
  justify-content: center;
}
.footer-store-links img {
  height: 40px;
  width: 133px;
}

.ios-link-wrapper {
  display: inline-block;
  overflow: hidden;
  border-radius: 13px;
  height: 40px;
  width: 133px;
}
.ios-link-wrapper .ios-link {
  border-radius: 13px;
  height: 40px;
  width: 133px;
}

.footer-store-links-mobile {
  background: rgb(228, 234, 240);
  border-radius: 0px;
  align-items: center;
  justify-content: space-between;
  height: 94px;
  width: 100vw;
  display: inline-flex;
  padding: 20px;
}
.footer-store-links-mobile img {
  height: 50px;
  width: 50px;
}
.footer-store-links-mobile .text-container {
  text-align: left;
}
.footer-store-links-mobile .text-container .store-text-upper {
  font-size: 13px;
  font-weight: 700;
}
.footer-store-links-mobile .text-container .store-text-lower {
  font-size: 11px;
  font-weight: 600;
}

.btn-login-small {
  background-color: #415ca3;
  color: #ffffff;
  border-radius: 4px;
  border: 1px;
  border-radius: 2px;
  height: 32px;
  margin: 5px;
  width: 20%;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
.btn-login-small:hover {
  opacity: 0.8;
  background-color: #415ca3;
}
.btn-login-small:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}
.btn-login-small:active {
  background-color: #415ca3 !important;
  color: #ffffff !important;
}
@media (max-width: 768px) {
  .btn-login-small {
    font-size: 14px;
  }
}

.sidebar {
  background-color: rgb(21, 29, 52) !important;
  font-size: 14px;
  height: 100vh;
  width: 230px;
}

.sidebar-minimized .sidebar .nav {
  width: 64px;
}

.sidebar .nav {
  width: 230px;
  flex-direction: column;
  min-height: 100vh !important;
}

.sidebar-fixed .sidebar {
  position: fixed;
  z-index: 1019;
  width: 230px;
  height: calc(100vh - 64px);
}

@media (min-width: 992px) {
  .sidebar-minimized.sidebar-fixed .sidebar {
    width: 64px;
  }
}

@media (max-width: 991.98px) {
  .sidebar {
    position: fixed;
    z-index: 1019;
    width: 230px;
    height: calc(100vh - 64px);
    margin-left: -230px;
  }
}
@media (min-width: 992px) {
  .sidebar-minimized .sidebar .nav-item {
    width: 64px;
  }
}

.sidebar .nav-item .nav-link {
  line-height: 60px;
}
.sidebar .nav-item .nav-link:hover {
  color: rgba(255, 255, 255, 0.65);
}

.sidebar-minimized .nav-item .nav-link {
  position: relative;
  padding-left: 0;
  margin: 0;
  overflow: hidden;
}
@media (min-width: 992px) {
  .sidebar-minimized .nav-item .nav-link:hover {
    background-color: #21293e;
    color: #eeeff4 !important;
  }
}
.sidebar-minimized .nav-item .nav-link:hover .badge {
  display: inline;
}
.sidebar-minimized .nav-item .nav-link.nav-dropdown-toggle::before {
  display: none;
}

.nav-link i {
  display: block;
  float: left;
  font-size: 18px;
}
.nav-link .badge {
  position: absolute;
  right: 15px;
  display: none;
}
@media (min-width: 992px) {
  .nav-link:hover {
    background-color: #21293e;
    color: #eeeff4 !important;
  }
}
.nav-link:hover .badge {
  display: inline;
}
.nav-link.nav-dropdown-toggle::before {
  display: none;
}

.nav-link .sidebar-fixed {
  color: rgba(255, 255, 255, 0.65);
  padding: 5px 0 !important;
}

.icona-nav {
  font-size: 24px !important;
  font-weight: 900px !important;
  margin: 10px 10px;
  width: 40px !important;
  vertical-align: middle;
}

.sidebar .nav-link, .sidebar .navbar .dropdown-toggle, .navbar .sidebar .dropdown-toggle {
  color: rgba(255, 255, 255, 0.65);
  padding: 5px 0 !important;
}

.sidebar .nav-item {
  position: relative;
  margin: 0;
  width: 230px;
  transition: background 0.3s ease-in-out;
}
.sidebar .nav-item:hover {
  background-color: rgba(90, 104, 114, 0.48) !important;
  color: #eeeff4 !important;
}

.sidebar .nav-link {
  position: relative;
  padding-left: 0;
  margin: 0;
  white-space: nowrap;
}
.sidebar .nav-link.active {
  background: linear-gradient(30deg, #0090d1, #00c3ea) !important;
  color: #fff !important;
}

.sidebar .nav-link.active:hover {
  background: #00c3ea !important;
  color: #fff !important;
}

@media (max-width: 991.98px) {
  .sidebar-mobile-show .sidebar {
    width: 230px;
    margin-left: 0;
  }
}
.sidebar-mobile-show .sidebar {
  width: 230px;
  margin-left: 0;
}
.sidebar-mobile-show .main {
  margin-right: -230px !important;
  margin-left: 230px !important;
}

.menuTooltip .bs-tooltip-auto[x-placement^=right] .arrow::before, .menuTooltip .bs-tooltip-right .arrow::before {
  border-right-color: #005075 !important;
}
.menuTooltip .tooltip-inner {
  border-radius: 0;
  background: #005075;
  opacity: 1;
}

.app-header.navbar {
  display: flex;
  border-color: #415ca3 !important;
  background-color: rgb(21, 41, 53) !important;
  height: 64px;
}

.header-fixed .app-body {
  margin-bottom: 50px;
}

.app-header * a {
  color: #415ca3;
}

.app-header * a:hover {
  text-decoration: none;
  color: rgb(0, 153, 255);
}

.brand-minimized .app-header.navbar .navbar-brand {
  font-size: 25px;
  height: 55px;
  line-height: 52px;
  margin-left: 10px !important;
}
@media (max-width: 736px) {
  .brand-minimized .app-header.navbar .navbar-brand {
    margin-left: 0 !important;
    height: 55px;
    width: 80px;
    line-height: initial;
  }
}

.app-header.navbar .navbar-toggler {
  width: 55px;
  font-size: 21px;
  padding: 1px 0px;
}
.app-header.navbar .navbar-toggler:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}

.app-header.navbar .navbar-brand {
  text-align: left;
  font-size: 24px;
  flex: 2;
  padding: 15px 0 0 0;
  font-size: 24px;
  vertical-align: middle;
  line-height: 10px;
  color: #fff;
  margin: 0px !important;
  margin-left: 15px;
  padding: 0px !important;
  height: 20px;
}
@media (max-width: 568px) {
  .app-header.navbar .navbar-brand {
    padding: 0;
    margin-top: 1px;
    line-height: 28px;
    margin-left: -37%;
    font-size: 22px;
    max-height: 50px;
  }
}
@media (max-width: 667px) {
  .app-header.navbar .navbar-brand {
    padding: 2px;
    line-height: 30px;
    margin-left: -37%;
  }
}
@media (min-width: 992px) {
  .app-header.navbar .navbar-brand {
    width: 50px;
    background-color: #415ca3;
    background-image: none;
    background-size: 24px;
    display: flex;
  }
}
@media (max-width: 991.98px) {
  .app-header.navbar .navbar-brand {
    position: relative !important;
    left: 0 !important;
    margin-left: 0px !important;
  }
}

.navbar {
  padding: 0 !important;
}

.app-header.navbar .navbar-brand {
  text-align: left;
  font-size: 24px;
  flex: 2;
  padding: 15px 0 0 0;
  vertical-align: middle;
  line-height: 10px;
  color: #fff;
  border: 1px solid rgb(21, 41, 53);
  margin: 0px !important;
  margin-left: 15px;
  padding: 0px !important;
  height: 20px;
  background-color: rgb(21, 41, 53) !important;
}
@media (max-width: 568px) {
  .app-header.navbar .navbar-brand {
    padding: 0;
    margin-top: 1px;
    line-height: 28px;
    margin-left: -37%;
    font-size: 22px;
    max-height: 50px;
  }
}
@media (max-width: 667px) {
  .app-header.navbar .navbar-brand {
    padding: 2px;
    line-height: 30px;
    margin-left: -37%;
  }
}
@media (min-width: 992px) {
  .app-header.navbar .navbar-brand {
    width: 50px;
    background-color: #rgb 21, 41, 53 !important;
    background-image: none;
    background-size: 24px;
    display: flex;
  }
}
@media (max-width: 991.98px) {
  .app-header.navbar .navbar-brand {
    position: relative !important;
    left: 0 !important;
    margin-left: 0px !important;
  }
}

.app-header.navbar .nav-item .nav-link > .img-avatar, .app-header.navbar .nav-item .dropdown-toggle > .img-avatar {
  height: 20px;
}

.app-header.navbar .nav-item .nav-link:hover {
  color: rgb(0, 153, 255);
}

.app-header.navbar .nav-item .nav-link:active {
  color: rgb(0, 153, 255);
}

.app-header.navbar {
  border: 0 !important;
  font-size: 24px;
}

@media (min-width: 660px) {
  .header-logged-name {
    padding-right: 20px;
    display: inline !important;
  }
}

.app-header.navbar .nav-item .nav-link:hover, .app-header.navbar .nav-item .dropdown-toggle:hover {
  color: white;
}

.app-header.navbar .nav-item .nav-link:active, .app-header.navbar .nav-item .dropdown-toggle:active {
  color: white;
}

.head-div-img {
  height: 64px;
  line-height: 58px;
}

.head-img {
  height: 24px;
}
@media (max-width: 736px) {
  .head-img {
    height: 24px;
    vertical-align: 1px;
  }
}

.user-div {
  padding: 0 20px;
  color: #fff;
  font-size: 15px;
}

.app-header.navbar .nav-item {
  position: relative;
  min-width: 50px;
  text-align: center;
}

.header-dropdown-user {
  font-size: 15px;
  background-color: inherit;
}
.header-dropdown-user svg {
  font-size: 24px;
}
.header-dropdown-user .nav-link {
  line-height: unset !important;
  padding: 10px 10px !important;
}
.header-dropdown-user .nav-link:hover {
  background-color: inherit !important;
  width: unset !important;
}
.header-dropdown-user.show button {
  display: block;
}

.ml-auto .navbar-nav {
  margin-left: auto !important;
  line-height: 50px;
  cursor: pointer;
  padding: 3px 20px 0px;
}
.ml-auto .navbar-nav a svg {
  width: 25px;
}

.dropdown-menu.show {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(-1px, 40px, 0px);
  font-size: 14px;
}
.dropdown-menu.show .dropdown-item {
  box-shadow: none !important;
  outline: none !important;
}
.dropdown-menu.show .dropdown-item svg {
  font-size: 14px;
}
.dropdown-menu.show .dropdown-item:not(:last-child) {
  content: "";
  margin: 0 auto;
  width: 95%;
  display: block;
  border-bottom: 1px solid #c2cfd6;
}

.app-header.navbar .dropdown-item {
  min-width: 180px;
  color: rgba(0, 0, 0, 0.65);
}

.app-header.navbar .dropdown-item:active {
  border: 1px solid #435cd0;
  color: #4e79f3;
  background-color: #fff;
}

.dropdown-item:active {
  border: 1px solid #435cd0;
  color: #4e79f3;
  background-color: #fff;
}

.app-header.navbar .nav-item .nav-link:hover, .app-header.navbar .nav-item .dropdown-toggle:hover {
  color: white;
}

.app-header.navbar .nav-item .nav-link:active, .app-header.navbar .nav-item .dropdown-toggle:active {
  color: white;
}

.user-text {
  float: left;
  margin: 8px 0 0 0;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  background-color: inherit;
}

.p-fisso {
  margin: 0px;
}

.p-variabile {
  margin: 0px;
}

.item-azienda {
  font-size: 15px;
  color: #fff;
}

.app-header.navbar .navbar-nav {
  flex-direction: row;
  align-items: center;
}

@media (max-width: 991.98px) {
  .d-md-down-none {
    display: none !important;
  }
}
.lista-aziende-drop button {
  background-color: inherit;
  border: none !important;
}
.lista-aziende-drop button:hover {
  background-color: inherit !important;
  border: none !important;
}
.lista-aziende-drop button:focus {
  background-color: inherit !important;
  border: none !important;
  box-shadow: none !important;
}
.lista-aziende-drop button:active {
  background-color: inherit !important;
  border: none !important;
  box-shadow: none !important;
  color: #fff;
}
.lista-aziende-drop.show {
  font-size: 14px;
}
.lista-aziende-drop.show .dropdown-item {
  box-shadow: none !important;
  outline: none !important;
}
.lista-aziende-drop.show .dropdown-item svg {
  font-size: 14px;
}
.lista-aziende-drop.show .dropdown-item .pinCompany {
  opacity: 0.5;
}
.lista-aziende-drop.show .dropdown-item .pinCompany.defaultCompany {
  opacity: 1;
}
.lista-aziende-drop.show .dropdown-item {
  display: flex;
}
.lista-aziende-drop.show .dropdown-item:not(:last-child) {
  content: "";
  margin: 0 auto;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #c2cfd6 !important;
}
.lista-aziende-drop.show .dropdown-menu {
  max-height: 350px;
  overflow: auto;
}

.breadcrumb {
  height: 56px;
  padding-left: 40px;
  color: #005075 !important;
  background-color: #fff !important;
  font-family: Cairo-SemiBold;
  font-size: 20px;
  line-height: 28px;
  border-bottom: 2px solid #0090d1;
  position: fixed;
  width: 100%;
  z-index: 1000;
  margin: 0;
  top: 64px;
}

@media (max-width: 768px) {
  .breadcrumb {
    padding: 1rem 12px !important;
    margin-bottom: 0;
  }
}
.active.breadcrumb-item {
  width: 100%;
  text-align: center;
  line-height: 100%;
}

.breadcrumb-item svg {
  margin: 0px;
  font-size: 27px;
  vertical-align: middle;
  margin-right: 1rem;
}

.dash-div {
  min-height: 320px;
  border-radius: 5px;
}
@media (max-width: 768px) {
  .dash-div {
    margin-bottom: 40px;
    font-size: 10px;
    min-height: 150px;
  }
}
.dash-div .img-box {
  margin-bottom: 0px;
  font-size: 30px;
}
.dash-div .box-title {
  font-size: 20px;
  font-weight: 700px;
}
.dash-div .box-bottom {
  position: absolute;
  bottom: 20px;
  margin-left: -25%;
  right: 25%;
  left: 50%;
}
@media (max-width: 768px) {
  .dash-div .box-bottom {
    margin-bottom: 40px;
  }
}
.dash-div .text-box {
  font-size: 16px;
  margin: 4% 0;
  padding: 0 20px;
}

.alert-dashboard {
  border: 1px solid rgb(0, 80, 117) !important;
  border-radius: 0;
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.65);
  width: 100%;
  border: none;
  min-height: 70px;
  align-items: center;
  line-height: 46px;
}
.alert-dashboard button.close {
  height: 100%;
  line-height: 0;
  font-size: 22px;
  color: rgb(21, 41, 53);
}
.alert-dashboard .close {
  font-size: 25px;
}
@media (max-width: 1290px) {
  .alert-dashboard .close {
    top: 0 !important;
  }
}

.btn-ambiti {
  border-radius: 4px;
  width: 100px;
  background-color: #415ca3;
  border: 1px solid #415ca3;
  padding: 8px;
  font-size: 14px;
  transition: opacity 0.15s ease-in-out;
}
.btn-ambiti:hover {
  opacity: 0.8;
  background-color: #415ca3;
  border: 1px solid #415ca3;
}
.btn-ambiti:active {
  background-color: #415ca3 !important;
  border: 1px solid #415ca3 !important;
}
.btn-ambiti:focus {
  box-shadow: 0 0 0 0 !important;
}

.alert-buttons {
  text-align: right;
}
@media screen and (min-device-width: 500px) and (max-device-width: 1300px) {
  .alert-buttons {
    right: inherit;
    top: 65%;
  }
}
@media (max-width: 500px) {
  .alert-buttons {
    position: initial;
    display: flex;
  }
}

.dashboard-card {
  height: 152px;
  margin-bottom: 24px;
}
.dashboard-card .card-right {
  float: right;
  background: #f2f5f8;
  height: 152px;
  width: 51px;
  display: flex;
  align-items: flex-end;
  text-align: center;
}
.dashboard-card .card-right a {
  padding: 10px;
  margin: 0 auto;
  border-top: 1px solid #c9d9e8;
}

.user-div .user-profile-panel {
  display: none;
  position: absolute;
  right: 0;
  color: #000;
  width: 420px;
  min-height: 200px;
  background: #fff;
  box-shadow: 0px -2px 8px 0px rgba(21, 41, 53, 0.24);
  margin-top: 10px;
}
.user-div .user-profile-panel .qr-container {
  margin: 0px 50px;
}
.user-div .user-profile-panel a {
  color: #000;
  cursor: pointer;
}
.user-div .user-profile-panel .link {
  cursor: pointer;
}
.user-div .user-profile-panel .user-profile-section {
  margin: 0 24px;
  padding-top: 20px;
  border-bottom: 1px solid #c9d9e8;
}
.user-div .user-profile-panel .user-profile-section p {
  margin: 0;
  padding: 0;
}
.user-div .show {
  display: block;
}

.language-popover {
  border-radius: 0;
  padding: 0;
  width: 240px;
}
.language-popover .popover-body {
  padding: 0 !important;
}
.language-popover .list-group-item {
  padding: 8px 16px;
  border: none;
}
.language-popover .list-group-item a {
  cursor: pointer;
  color: #0099ff;
}
.language-popover .list-group-item.active {
  background: rgb(222, 240, 247);
  color: rgb(0, 144, 209);
}

#page-gestione-utenti .nav-link {
  font-family: "Cairo-SemiBold";
  padding: 0 15px;
  font-size: 24px;
  border: none;
}
#page-gestione-utenti .tab-content {
  border: none;
  background-color: #fff !important;
  padding: 20px;
}
#page-gestione-utenti .div-licenze {
  box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
  border-radius: 0;
  font-family: Cairo-SemiBold;
  color: rgb(0, 80, 117);
  font-size: 24px;
}
#page-gestione-utenti .btn-primary {
  padding: 8px 12px;
  font-size: 14px;
}
#page-gestione-utenti .btn-primary:hover {
  opacity: 0.8;
  background-color: #415ca3;
}
#page-gestione-utenti .btn-primary:focus {
  box-shadow: 0 0 0 0;
}
@media (max-width: 768px) {
  #page-gestione-utenti .btn-primary {
    font-size: 16px;
  }
}

.div-licenze {
  line-height: 47px;
  padding-left: 10px;
  font-size: 16px;
}
@media (max-width: 768px) {
  .div-licenze {
    padding: 0 5px;
    line-height: 15px;
  }
}

.div-numero-licenze {
  padding: 0;
  float: right;
  font-size: 24px;
}
.div-numero-licenze span b {
  padding-right: 15px;
}
@media (max-width: 768px) {
  .div-numero-licenze {
    font-size: 15px;
  }
}

.riga-ricerca-utente {
  margin-top: 15px;
}
@media (max-width: 768px) {
  .riga-ricerca-utente {
    margin-top: 20px;
  }
}

.riga-ricerca-utente-inviti {
  margin-top: 20px;
  max-width: 398px;
}

.riga-utenti {
  margin-top: 25px;
  align-items: center;
}
@media (max-width: 768px) {
  .riga-utenti {
    padding: 0px;
  }
}

@media (max-width: 768px) {
  div.riga-console-utenti > * {
    padding: 0px;
  }
}

.riga-licenze {
  height: 40px;
  margin-bottom: 55px;
}
@media (max-width: 768px) {
  .riga-licenze {
    height: 0px;
  }
}

.riga-console-tabella {
  margin-top: 20px;
  flex: 1 1 auto;
}
@media (max-width: 768px) {
  .riga-console-tabella {
    padding: 0px;
  }
}

@media (max-width: 768px) {
  .colonna-console-tabella {
    padding: 0px;
  }
}

.label-form {
  display: inline-block;
  padding-right: 0.3rem;
}

@media (min-width: 576px) {
  .form-inline .form-group {
    display: inline;
  }
}

@media (min-width: 576px) {
  .form-inline label {
    display: inherit;
  }
}

.input-cerca-inviti {
  max-width: 400px;
}

.btn-form-blue {
  min-width: 150px;
}
.btn-form-blue:disabled {
  background-color: #ccc !important;
  border-color: #ccc !important;
}

.btn-form-blue-cerca {
  margin-left: 10px;
}

.table tr {
  line-height: 35px;
}
@media (max-width: 1200px) {
  .table tr th {
    font-size: 12px;
    padding: 2px;
  }
}

.table thead th {
  border: 0px;
  font-size: 12px;
  font-weight: 700;
  background-color: #fff;
  border-bottom: 1px solid rgb(157, 166, 173);
  color: rgb(0, 80, 117);
}
@media (min-width: 1200px) {
  .table thead th {
    font-size: 15px;
  }
}

.table th:nth-child(1) {
  width: 15px;
}

.table th:nth-child(8) {
  width: 155px;
}

.table td, .table th {
  border: 1px;
  font-weight: 400;
  font-size: 12px;
  border-bottom: 1px solid rgb(157, 166, 173);
}
@media (min-width: 1200px) {
  .table td, .table th {
    font-size: 15px;
  }
}

.col-button {
  float: right;
}

.label-form {
  display: inline-block;
  padding-right: 0.3rem;
}

.label-form-inviti {
  display: inline-block;
  padding-right: 0.3rem;
}
@media (min-width: 576px) {
  .form-inline .form-group {
    display: inline;
  }
}

@media (min-width: 576px) {
  .form-inline label {
    display: inherit;
  }
}

.btn-form-aggiungi {
  border-radius: 5px;
  background-color: #fff;
  border: 1px #fff;
  margin: auto;
  width: 100%;
  border: 1px dotted #000;
  font-size: 14px;
  color: #000;
}
.btn-form-aggiungi:hover {
  color: #000;
}
.btn-form-aggiungi:active {
  color: #000;
  border: 1px dotted #000 !important;
}
.btn-form-aggiungi:focus {
  box-shadow: none !important;
}
.btn-form-aggiungi.disabled {
  background-color: #fff;
  border: 1px dotted rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0.3);
}
@media (max-width: 768px) {
  .btn-form-aggiungi {
    font-size: 16px;
  }
}

.btn-form-aggiungi:active, .btn-form-aggiungi.active {
  border-radius: 5px;
  background-color: #fff;
  border: 1px #fff;
  min-width: 75px;
  border: 1px dotted #000;
  font-size: 14px;
}
@media (max-width: 768px) {
  .btn-form-aggiungi:active, .btn-form-aggiungi.active {
    font-size: 16px;
  }
}

.btn-form-aggiungi:hover, .btn-form-aggiungi.hover {
  border-radius: 5px;
  background-color: #fff;
  border: 1px #fff;
  min-width: 75px;
  border: 1px dotted #000;
  font-size: 14px;
}
@media (max-width: 768px) {
  .btn-form-aggiungi:hover, .btn-form-aggiungi.hover {
    font-size: 16px;
  }
}

.input-form {
  border-radius: 5px;
  font-size: 14px;
  margin-left: 25px;
}
@media (max-width: 768px) {
  .input-form {
    font-size: 16px;
  }
}
.input-form.error {
  border: 1px solid red;
}

.input-form-nuovo {
  border-radius: 5px;
  margin-left: 5px;
  font-size: 14px;
}
@media (max-width: 768px) {
  .input-form-nuovo {
    font-size: 16px;
  }
}

.form-inline .form-control {
  width: auto;
}

.dropdown-licenze {
  height: 47px;
  padding-top: 0;
  top: 0;
  color: rgb(0, 80, 117);
  background: rgb(242, 245, 248);
  vertical-align: top;
}
.dropdown-licenze button {
  border: none;
  background-color: #fff;
  color: #000;
}
.dropdown-licenze button:focus {
  box-shadow: none !important;
}
.dropdown-licenze button:hover {
  background-color: #fff;
  color: #000;
}
.dropdown-licenze button:hover:active {
  background-color: #fff;
  color: #000;
}
.dropdown-licenze button:hover:focus {
  box-shadow: none !important;
}

.btn-form-primary {
  background-color: #415ca3;
  border: 1px solid #415ca3;
  padding: 8px 12px;
  font-size: 14px;
}
.btn-form-primary:hover {
  opacity: 0.8;
  background-color: #415ca3;
}
.btn-form-primary:active {
  opacity: 0.8;
  background-color: #415ca3 !important;
}
.btn-form-primary:focus {
  box-shadow: none !important;
}
@media (max-width: 768px) {
  .btn-form-primary {
    font-size: 16px;
  }
}

.btn-form-secondary {
  background-color: #fff;
  border: 1px solid rgb(204, 204, 204);
  padding: 8px 12px;
  color: #797979;
  font-size: 14px;
}
.btn-form-secondary:hover {
  color: #797979;
  opacity: 0.8;
  background-color: #fff;
  border: 1px solid rgb(204, 204, 204);
}
.btn-form-secondary:active {
  border: 1px solid #415ca3 !important;
}
.btn-form-secondary:active:focus {
  box-shadow: none !important;
}
.btn-form-secondary:focus {
  box-shadow: none !important;
}
@media (max-width: 768px) {
  .btn-form-secondary {
    font-size: 16px;
  }
}

.single-tag-blue {
  border-radius: 5px;
  left: 0px;
  top: 0px;
  width: 150px;
  height: 20px;
  background: #fff;
  border: 1px solid rgb(13, 108, 128);
  font-family: Cairo-SemiBold;
  color: rgb(13, 108, 128);
  font-size: 12px;
  padding: 0 5px;
}

.single-tag-red {
  border-radius: 5px;
  left: 0px;
  top: 0px;
  width: 150px;
  height: 20px;
  background: inherit;
  border: 1px solid rgb(197, 48, 144);
  font-family: Cairo-SemiBold;
  font-size: 12px;
  color: rgb(197, 48, 144);
  padding: 0 5px;
}

.single-tag-green {
  border-radius: 5px;
  left: 0px;
  top: 0px;
  width: 150px;
  height: 20px;
  background: inherit;
  border: 1px solid rgb(48, 197, 63);
  font-family: Cairo-SemiBold;
  font-size: 12px;
  color: rgb(48, 197, 63);
  padding: 0 5px;
}

.single-tag-violet {
  border-radius: 5px;
  left: 0px;
  top: 0px;
  width: 150px;
  height: 20px;
  background: inherit;
  border: 1px solid rgb(119, 69, 236);
  font-family: Cairo-SemiBold;
  font-size: 12px;
  color: rgb(119, 69, 236);
  padding: 0 5px;
}

.modal-dialog {
  max-width: 734px !important;
}
.modal-dialog .form-inviti {
  line-height: 35px;
}
.modal-dialog .form-inviti .col-sm-12 {
  padding: 20px 15px;
}
.modal-dialog .form-inviti .form-group .input-form {
  margin: 0;
  width: calc(100% - 84px);
}
.modal-dialog .form-inviti .form-group .custom-switch {
  display: inline-block;
  padding: 0;
}
.modal-dialog .form-inviti .form-group .custom-switch label {
  display: inline-block;
  text-align: inherit;
  width: auto;
  padding-right: 0;
  margin-right: 0;
  margin-left: 37px;
}
.modal-dialog .form-inviti .form-group .switch-component {
  vertical-align: middle;
}
.modal-dialog .form-inviti .form-group .switch-component input[type=checkbox] {
  display: none;
}
.modal-dialog .form-inviti .form-group .switch-component-table {
  vertical-align: middle;
  margin-top: 10px !important;
}
.modal-dialog .form-inviti .form-group .switch-component-table input[type=checkbox] {
  display: none;
}

.dropdown-filtro {
  margin-left: 5px;
  font-size: 10px;
}
.dropdown-filtro button {
  background-color: #fff !important;
  border: none;
  padding: 0px;
  font-size: 12px;
  color: gray;
}
.dropdown-filtro button:hover {
  color: #000;
}
.dropdown-filtro button:focus {
  box-shadow: none !important;
}

.item-filtro {
  display: inline-flex;
  margin: 0 10px;
  line-height: 28px;
}
.item-filtro .opzione-filtro {
  float: right;
}
.item-filtro:focus {
  box-shadow: none;
}

.drop-menu-controls {
  width: 100%;
  padding: 10px;
  display: inline-flex;
  justify-content: space-between;
}
.drop-menu-controls button {
  color: #435CA3;
}

.icona-filtro {
  margin-left: 5px;
  font-size: 12px;
  color: rgb(0, 80, 117);
}
.icona-filtro:hover {
  color: #000;
}

.drop-item {
  border: none;
}
.drop-item:active {
  border: none !important;
  color: unset !important;
}
.drop-item:not(:last-child) {
  border: none !important;
}

.dropmenu-filtro {
  padding: 0 1px;
}

.error-message {
  color: red;
  font-size: 10px;
  padding-right: 5px;
}

.error-message-invited {
  color: red;
  font-size: 10px;
  padding-right: 5px;
  font-weight: bold;
}

.more-solutions {
  width: 30px;
  margin-left: 5px;
  height: 25px;
  background: inherit;
  background-color: rgba(67, 92, 163, 0.0980392157);
  font-size: 12px;
  color: #435CA3;
  padding: 5px;
  border: none;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.more-solutions:hover {
  background-color: rgba(67, 92, 163, 0.0980392157);
}

.drop-item-solutions {
  height: 40px;
}
.drop-item-solutions .dropdown-item {
  padding: 0 !important;
}

@media (max-width: 701px) {
  .col-interfaccia {
    max-width: 30vw !important;
  }
}
@media (min-width: 991) {
  .col-interfaccia {
    max-width: 90vh !important;
  }
}

@media (max-width: 701px) {
  .col-vuota {
    max-width: 30vw;
  }
}
@media (min-width: 991) {
  .col-vuota {
    max-width: 50vh;
  }
}

.div-btn-right {
  float: right;
}

.modal-riepilogo-intestazione {
  margin: 10px 0 20px 0;
}

.scrollable-body {
  max-height: 200px;
  overflow-x: auto;
  overflow-x: hidden;
  margin-bottom: 20px;
}

.div-riepilogo-invito {
  border-bottom: 1px solid #d9d9d9;
}
.div-riepilogo-invito div {
  margin: 0 5px;
  display: inline-flex;
  width: 100%;
}
.div-riepilogo-invito div span:nth-child(1) {
  width: 40%;
}
.div-riepilogo-invito div span:nth-child(2) {
  width: 60%;
}

.collapsible-baseurl {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-top: 30px;
  background-color: rgba(217, 217, 217, 0.2470588235);
  width: 100%;
}
.collapsible-baseurl .collapsed {
  margin: 10px 20px;
}
.collapsible-baseurl .card {
  border: none;
}

.input-base-url {
  border-radius: 5px !important;
}

.drop-toggle-baseurl {
  margin-left: 1px;
  padding: 8px 12px !important;
  border-radius: 2px;
  background-color: #415ca3 !important;
}
.drop-toggle-baseurl:hover {
  background-color: #415ca3 !important;
}
.drop-toggle-baseurl:active {
  background-color: #415ca3 !important;
}
.drop-toggle-baseurl:focus {
  box-shadow: none !important;
}

.popover.show.bs-popover-top {
  width: 9150px;
}

.controls-pop-env {
  margin: 20px 0;
  display: inline-flex;
  float: right;
  justify-content: space-between;
}
.controls-pop-env button:nth-child(1) {
  margin-right: 15px;
  height: 30px !important;
  padding: 0px 25px !important;
  opacity: 0.8;
}
.controls-pop-env button:nth-child(1):hover {
  opacity: 0.8 !important;
  background-color: #fff;
}
.controls-pop-env button:nth-child(1):active {
  opacity: 0.8 !important;
  background-color: #fff;
}
.controls-pop-env button:nth-child(1):focus {
  box-shadow: none !important;
}
.controls-pop-env button:nth-child(2) {
  color: #ffffff;
  height: 30px !important;
  padding: 0px 25px !important;
  background-color: #415ca3 !important;
}
.controls-pop-env button:nth-child(2):hover {
  opacity: 0.8 !important;
  background-color: #415ca3 !important;
  border: 1px solid #415ca3 !important;
}
.controls-pop-env button:nth-child(2):active {
  opacity: 0.8 !important;
  background-color: #415ca3 !important;
  border: 1px solid #415ca3 !important;
}
.controls-pop-env button:nth-child(2):focus {
  box-shadow: none !important;
}

.switch-component {
  margin: 0;
}
.switch-component .react-switch-bg * svg {
  display: none;
}

.switch-component-table {
  margin: 10px 0 0 0;
}
.switch-component-table .react-switch-bg * svg {
  display: none;
}

input.form-control {
  border: 1px solid rgb(201, 217, 232);
  border-radius: 0;
}

.rst__row {
  height: 90%;
  min-width: 300px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}
.rst__row .rst__moveHandle {
  width: 30px;
}
.rst__row .rst__rowTitle {
  font-weight: normal;
}
.rst__row .rst__moveHandle, .rst__row .rst__loadingHandle {
  outline: none;
  border: 0;
}
.rst__row .rst__rowContents {
  padding-left: 5px;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.rst__row.INVITE {
  min-width: 350px;
}
.rst__row.INVITE .span-icona {
  margin-right: 20px;
}
.rst__row.INVITE .rst__moveHandle, .rst__row.INVITE .rst__loadingHandle {
  background: #ffffff url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTVweCIgaGVpZ3RoPSIyMHB4IiBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJncmlwLWxpbmVzIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtZ3JpcC1saW5lcyBmYS13LTIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0icmdiKDAsIDE0NCwgMjA5KSIgZD0iTTQ5NiAyODhIMTZjLTguOCAwLTE2IDcuMi0xNiAxNnYzMmMwIDguOCA3LjIgMTYgMTYgMTZoNDgwYzguOCAwIDE2LTcuMiAxNi0xNnYtMzJjMC04LjgtNy4yLTE2LTE2LTE2em0wLTEyOEgxNmMtOC44IDAtMTYgNy4yLTE2IDE2djMyYzAgOC44IDcuMiAxNiAxNiAxNmg0ODBjOC44IDAgMTYtNy4yIDE2LTE2di0zMmMwLTguOC03LjItMTYtMTYtMTZ6Ij48L3BhdGg+PC9zdmc+") no-repeat center;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.rst__row.INVITE:hover {
  border: 1px solid rgb(0, 154, 218) !important;
}
.rst__row.INVITE.active .rst__rowContents {
  background-color: rgb(222, 240, 247) !important;
  color: rgb(0, 154, 218);
}
.rst__row.INVITE.active .rst__moveHandle, .rst__row.INVITE.active .rst__loadingHandle {
  background: rgb(222, 240, 247) url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTVweCIgaGVpZ3RoPSIyMHB4IiBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJncmlwLWxpbmVzIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtZ3JpcC1saW5lcyBmYS13LTIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0icmdiKDAsIDE0NCwgMjA5KSIgZD0iTTQ5NiAyODhIMTZjLTguOCAwLTE2IDcuMi0xNiAxNnYzMmMwIDguOCA3LjIgMTYgMTYgMTZoNDgwYzguOCAwIDE2LTcuMiAxNi0xNnYtMzJjMC04LjgtNy4yLTE2LTE2LTE2em0wLTEyOEgxNmMtOC44IDAtMTYgNy4yLTE2IDE2djMyYzAgOC44IDcuMiAxNiAxNiAxNmg0ODBjOC44IDAgMTYtNy4yIDE2LTE2di0zMmMwLTguOC03LjItMTYtMTYtMTZ6Ij48L3BhdGg+PC9zdmc+") no-repeat center;
  box-shadow: none;
  border-left: 2px solid #0090d1;
}
.rst__row.entryGroup {
  color: rgb(0, 80, 117);
}
.rst__row.entryGroup .rst__rowContents {
  border: 0;
}
.rst__row.entryGroup .rst__rowContents:not(:hover) .btn-group {
  display: none;
}
.rst__row.entryGroup .rst__rowContents:hover .btn-group {
  display: block;
}
.rst__row.entryGroup .rst__moveHandle {
  display: none;
}
.rst__row.entryGroup .rst__moveHandle, .rst__row.entryGroup .rst__loadingHandle {
  background: #151D34 url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTVweCIgaGVpZ3RoPSIyMHB4IiBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJncmlwLWxpbmVzIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtZ3JpcC1saW5lcyBmYS13LTIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0icmdiKDAsIDE0NCwgMjA5KTsiIGQ9Ik00OTYgMjg4SDE2Yy04LjggMC0xNiA3LjItMTYgMTZ2MzJjMCA4LjggNy4yIDE2IDE2IDE2aDQ4MGM4LjggMCAxNi03LjIgMTYtMTZ2LTMyYzAtOC44LTcuMi0xNi0xNi0xNnptMC0xMjhIMTZjLTguOCAwLTE2IDcuMi0xNiAxNnYzMmMwIDguOCA3LjIgMTYgMTYgMTZoNDgwYzguOCAwIDE2LTcuMiAxNi0xNnYtMzJjMC04LjgtNy4yLTE2LTE2LTE2eiI+PC9wYXRoPjwvc3ZnPg==") no-repeat center;
  box-shadow: none;
}
.rst__row.entryGroup:hover {
  border: 1px solid rgb(0, 154, 218) !important;
}
.rst__row.entryGroup.active .rst__rowContents {
  background-color: rgb(222, 240, 247) !important;
  color: rgb(0, 154, 218);
  border-left: 2px solid #0090d1 !important;
}
.rst__row.entryGroup.active .rst__moveHandle, .rst__row.entryGroup.active .rst__loadingHandle {
  background: rgb(222, 240, 247) url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTVweCIgaGVpZ3RoPSIyMHB4IiBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJncmlwLWxpbmVzIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtZ3JpcC1saW5lcyBmYS13LTIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0icmdiKDAsIDE0NCwgMjA5KSIgZD0iTTQ5NiAyODhIMTZjLTguOCAwLTE2IDcuMi0xNiAxNnYzMmMwIDguOCA3LjIgMTYgMTYgMTZoNDgwYzguOCAwIDE2LTcuMiAxNi0xNnYtMzJjMC04LjgtNy4yLTE2LTE2LTE2em0wLTEyOEgxNmMtOC44IDAtMTYgNy4yLTE2IDE2djMyYzAgOC44IDcuMiAxNiAxNiAxNmg0ODBjOC44IDAgMTYtNy4yIDE2LTE2di0zMmMwLTguOC03LjItMTYtMTYtMTZ6Ij48L3BhdGg+PC9zdmc+") no-repeat center;
  box-shadow: none;
  border-left: 2px solid #0090d1 !important;
}
.rst__row.GROUP {
  color: rgb(21, 41, 53);
  background: none;
  font-size: 16px;
}
.rst__row.GROUP .rst__rowContents {
  border: 0;
  border-radius: 0;
}
.rst__row.GROUP .rst__rowContents:not(:hover) .btn-group {
  display: none;
}
.rst__row.GROUP .rst__rowContents:hover .btn-group {
  display: block;
}
.rst__row.GROUP .rst__moveHandle, .rst__row.GROUP .rst__loadingHandle {
  background: #fff url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTVweCIgaGVpZ3RoPSIyMHB4IiBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJncmlwLWxpbmVzIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtZ3JpcC1saW5lcyBmYS13LTIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0icmdiKDAsIDE0NCwgMjA5KSIgZD0iTTQ5NiAyODhIMTZjLTguOCAwLTE2IDcuMi0xNiAxNnYzMmMwIDguOCA3LjIgMTYgMTYgMTZoNDgwYzguOCAwIDE2LTcuMiAxNi0xNnYtMzJjMC04LjgtNy4yLTE2LTE2LTE2em0wLTEyOEgxNmMtOC44IDAtMTYgNy4yLTE2IDE2djMyYzAgOC44IDcuMiAxNiAxNiAxNmg0ODBjOC44IDAgMTYtNy4yIDE2LTE2di0zMmMwLTguOC03LjItMTYtMTYtMTZ6Ij48L3BhdGg+PC9zdmc+") no-repeat center;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.rst__row.GROUP.active .rst__rowContents {
  background-color: rgb(222, 240, 247) !important;
  color: rgb(0, 154, 218);
}
.rst__row.GROUP.active .rst__moveHandle, .rst__row.GROUP.active .rst__loadingHandle {
  background: rgb(222, 240, 247) url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTVweCIgaGVpZ3RoPSIyMHB4IiBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJncmlwLWxpbmVzIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtZ3JpcC1saW5lcyBmYS13LTIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0icmdiKDAsIDE0NCwgMjA5KSIgZD0iTTQ5NiAyODhIMTZjLTguOCAwLTE2IDcuMi0xNiAxNnYzMmMwIDguOCA3LjIgMTYgMTYgMTZoNDgwYzguOCAwIDE2LTcuMiAxNi0xNnYtMzJjMC04LjgtNy4yLTE2LTE2LTE2em0wLTEyOEgxNmMtOC44IDAtMTYgNy4yLTE2IDE2djMyYzAgOC44IDcuMiAxNiAxNiAxNmg0ODBjOC44IDAgMTYtNy4yIDE2LTE2di0zMmMwLTguOC03LjItMTYtMTYtMTZ6Ij48L3BhdGg+PC9zdmc+") no-repeat center;
  box-shadow: none;
  border-left: 2px solid #0090d1;
}
.rst__row.GROUP.default {
  font-style: italic;
}
.rst__row.GROUP:hover {
  border: 1px solid rgb(0, 154, 218) !important;
}
.rst__row.GROUP.edit .rst__rowContents {
  background-color: #ffffff;
  color: white;
  border: 0;
  border-radius: 0;
}
.rst__row.GROUP.edit .rst__rowContents .rst__rowLabel {
  display: none;
}
.rst__row.GROUP.edit .rst__rowToolbar input {
  margin-right: 10px;
  background-color: rgb(242, 245, 248);
  border: 1px solid rgb(0, 144, 209);
}
.rst__row.GROUP.edit .rst__rowToolbar button {
  height: 32px;
  width: 68px;
  color: #ffffff;
  background: inherit;
  background: linear-gradient(-26.5650511771deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
  border-radius: 2px;
  border: none;
}
.rst__row.USER {
  min-width: 350px;
}
.rst__row.USER .rst__rowContents:not(:hover) svg {
  display: none;
}
.rst__row.USER .rst__rowContents:hover svg {
  display: block;
}
.rst__row.USER .rst__moveHandle, .rst__row.USER .rst__loadingHandle {
  background: #ffffff url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTVweCIgaGVpZ3RoPSIyMHB4IiBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJncmlwLWxpbmVzIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtZ3JpcC1saW5lcyBmYS13LTIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0icmdiKDAsIDE0NCwgMjA5KSIgZD0iTTQ5NiAyODhIMTZjLTguOCAwLTE2IDcuMi0xNiAxNnYzMmMwIDguOCA3LjIgMTYgMTYgMTZoNDgwYzguOCAwIDE2LTcuMiAxNi0xNnYtMzJjMC04LjgtNy4yLTE2LTE2LTE2em0wLTEyOEgxNmMtOC44IDAtMTYgNy4yLTE2IDE2djMyYzAgOC44IDcuMiAxNiAxNiAxNmg0ODBjOC44IDAgMTYtNy4yIDE2LTE2di0zMmMwLTguOC03LjItMTYtMTYtMTZ6Ij48L3BhdGg+PC9zdmc+") no-repeat center;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.rst__row.USER:hover {
  border: 1px solid rgb(0, 154, 218) !important;
}
.rst__row.USER.active .rst__rowContents {
  background-color: rgb(222, 240, 247) !important;
  color: rgb(0, 154, 218);
}
.rst__row.USER.active .rst__moveHandle, .rst__row.USER.active .rst__loadingHandle {
  background: rgb(222, 240, 247) url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTVweCIgaGVpZ3RoPSIyMHB4IiBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJncmlwLWxpbmVzIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtZ3JpcC1saW5lcyBmYS13LTIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0icmdiKDAsIDE0NCwgMjA5KSIgZD0iTTQ5NiAyODhIMTZjLTguOCAwLTE2IDcuMi0xNiAxNnYzMmMwIDguOCA3LjIgMTYgMTYgMTZoNDgwYzguOCAwIDE2LTcuMiAxNi0xNnYtMzJjMC04LjgtNy4yLTE2LTE2LTE2em0wLTEyOEgxNmMtOC44IDAtMTYgNy4yLTE2IDE2djMyYzAgOC44IDcuMiAxNiAxNiAxNmg0ODBjOC44IDAgMTYtNy4yIDE2LTE2di0zMmMwLTguOC03LjItMTYtMTYtMTZ6Ij48L3BhdGg+PC9zdmc+") no-repeat center;
  box-shadow: none;
  border-left: 2px solid #0090d1;
}

.rst__rowContents {
  border: none !important;
}

.auth-header {
  color: rgb(0, 80, 117);
  font-family: Cairo-SemiBold;
}
.auth-header h4 {
  font-size: 16px;
}

.side-view {
  background-color: white;
  position: fixed;
  height: calc(100vh - 120px);
  margin-top: 120px;
  width: 0px;
  top: 0;
  right: 0;
  -webkit-box-shadow: -4px 0px 6px -2px rgb(89, 89, 89);
  -moz-box-shadow: -4px 0px 6px -2px rgb(89, 89, 89);
  box-shadow: -4px 0px 6px -2px rgb(89, 89, 89);
  transition: width 0.5s, visibility 0.5s, box-shadow 0.5s;
  visibility: hidden;
  z-index: 101;
}
.side-view .auth-header-wrapper {
  border-width: 0px 0px 1px;
  border-style: solid;
  border-color: rgb(217, 217, 217);
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid #0090d1;
}
.side-view .auth-header-wrapper .auth-header {
  margin: 20px;
}
.side-view .auth-header-wrapper .auth-header .h2 {
  font-size: "20px";
  font-weight: 700;
}
.side-view .container {
  padding: 0px !important;
  padding-bottom: 10px;
  margin-bottom: 30px;
  margin-top: 0px;
}
.side-view .container .auth-header {
  border-bottom: 1px solid rgb(217, 217, 217);
}
.side-view .container .input-wrapper {
  display: flex;
  padding: 30px 0;
  width: 100%;
}
.side-view .container .input-wrapper input {
  max-width: 80%;
}
.side-view .container .input-wrapper button {
  background-color: #415ca3 !important;
}
@media (min-height: 1200px) {
  .side-view .container .div-catalog {
    max-height: 60vh !important;
  }
}
@media (min-height: 920px) and (max-height: 1190px) {
  .side-view .container .div-catalog {
    max-height: 55vh !important;
  }
}
@media (min-height: 720px) and (max-height: 900px) {
  .side-view .container .div-catalog {
    max-height: 50vh !important;
  }
}
.side-view .container .div-catalog {
  margin-top: 30px;
  margin-left: 15px;
  max-height: 40vh;
  overflow-y: auto;
}
.side-view .container .div-catalog .catalog-title {
  background-color: rgb(250, 250, 250);
  border-bottom: 1px solid rgb(233, 233, 233);
  padding: 10px;
  color: rgb(0, 80, 117);
  font-family: Cairo-SemiBold;
  font-size: 16px;
  font-weight: 600;
}
.side-view .container .div-catalog .dropdown-menu.show {
  padding: 0px !important;
}
.side-view .container .div-catalog .span-cat-object {
  padding: 10px 10px 10px 10px;
  border-color: rgb(157, 166, 173);
  border-width: 1px;
  border-style: solid;
  border-left: 0px;
  border-top: 0px;
  border-right: 0px;
  border-radius: 0px;
}
.side-view .container .div-catalog .span-cat-object .btn-group {
  width: 100%;
}
.side-view .container .div-catalog .span-cat-object .btn-group button {
  color: unset !important;
  text-align: left;
}
.side-view .container .div-catalog .span-cat-object .btn-group button:active:hover {
  box-shadow: none;
}
.side-view .container .div-catalog .span-cat-object svg {
  margin-right: 10px;
}
.side-view .container .div-catalog .span-cat-object.level0 {
  font-weight: bold !important;
}
.side-view .container .div-catalog .span-cat-object.level1 {
  font-weight: bold !important;
}

.input-form .error {
  outline: red;
}

.side-view-overlay {
  background-color: rgba(169, 169, 169, 0.3);
  position: fixed;
  width: 100%;
  height: calc(100vh - 55px);
  top: 0;
  left: 0;
  transition: opacity 0.5s, visibility 0.5s;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
}

.side-view-show {
  visibility: visible;
  width: 800px;
}

.side-view-overlay-show {
  visibility: visible;
  opacity: 0.3;
  height: 100vh;
  background-color: rgb(0, 0, 0);
}

@media screen and (max-width: 800px) {
  .side-view-show {
    width: 100%;
  }
  .side-view-overlay {
    width: 0;
  }
}
.btn-group > button {
  background: inherit;
  border: none;
}

.btn-group > button:hover {
  background: inherit;
  border: none;
}

.btn-group > button:not(:disabled):not(.disabled):active {
  background-color: inherit !important;
  color: #fff !important;
  border: none;
}

.btn-group > button:focus {
  background-color: inherit !important;
  color: #fff !important;
  border: none;
  box-shadow: none;
}

.rst__toolbarButton .dropdown-menu.show .dropdown-item:not(:last-child) {
  width: 100%;
}

.rst__nodeContent .rst__collapseButton {
  background: #fff url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaGV2cm9uLWRvd24iIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1jaGV2cm9uLWRvd24gZmEtdy0xNCIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik0yMDcuMDI5IDM4MS40NzZMMTIuNjg2IDE4Ny4xMzJjLTkuMzczLTkuMzczLTkuMzczLTI0LjU2OSAwLTMzLjk0MWwyMi42NjctMjIuNjY3YzkuMzU3LTkuMzU3IDI0LjUyMi05LjM3NSAzMy45MDEtLjA0TDIyNCAyODQuNTA1bDE1NC43NDUtMTU0LjAyMWM5LjM3OS05LjMzNSAyNC41NDQtOS4zMTcgMzMuOTAxLjA0bDIyLjY2NyAyMi42NjdjOS4zNzMgOS4zNzMgOS4zNzMgMjQuNTY5IDAgMzMuOTQxTDI0MC45NzEgMzgxLjQ3NmMtOS4zNzMgOS4zNzItMjQuNTY5IDkuMzcyLTMzLjk0MiAweiI+PC9wYXRoPjwvc3ZnPg==") no-repeat center !important;
  color: rgb(21, 41, 53);
  border: none !important;
  border-radius: unset !important;
  box-shadow: none !important;
}
.rst__nodeContent .rst__expandButton {
  background: #fff url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaGV2cm9uLXJpZ2h0IiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY2hldnJvbi1yaWdodCBmYS13LTIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzIwIDUxMiI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMjg1LjQ3NiAyNzIuOTcxTDkxLjEzMiA0NjcuMzE0Yy05LjM3MyA5LjM3My0yNC41NjkgOS4zNzMtMzMuOTQxIDBsLTIyLjY2Ny0yMi42NjdjLTkuMzU3LTkuMzU3LTkuMzc1LTI0LjUyMi0uMDQtMzMuOTAxTDE4OC41MDUgMjU2IDM0LjQ4NCAxMDEuMjU1Yy05LjMzNS05LjM3OS05LjMxNy0yNC41NDQuMDQtMzMuOTAxbDIyLjY2Ny0yMi42NjdjOS4zNzMtOS4zNzMgMjQuNTY5LTkuMzczIDMzLjk0MSAwTDI4NS40NzUgMjM5LjAzYzkuMzczIDkuMzcyIDkuMzczIDI0LjU2OC4wMDEgMzMuOTQxeiI+PC9wYXRoPjwvc3ZnPg==") no-repeat center !important;
  color: rgb(21, 41, 53);
  border: none !important;
  border-radius: unset !important;
  box-shadow: none !important;
}

.auth-main-page {
  background: #fff;
  max-width: inherit !important;
  display: flex;
  align-items: flex-start;
}
.auth-main-page .tree-container {
  max-width: inherit !important;
  padding: 20px;
  width: 45%;
  margin-right: 20px;
  height: calc(100vh - 64px - 56px);
}
.auth-main-page .tree-container .rst__tree {
  max-width: 90% !important;
  height: 90% !important;
}
.auth-main-page .tree-container .create-group {
  border: 1px solid rgb(0, 144, 209);
  background-color: #fff;
  color: rgb(0, 144, 209);
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  margin-top: 20px;
}
.auth-main-page .tree-container .create-group svg {
  margin-right: 10px;
}
.auth-main-page .tree-container .create-group:focus, .auth-main-page .tree-container .create-group:active {
  border: 1px solid rgb(0, 144, 209);
  color: rgb(0, 144, 209);
}
.auth-main-page .company-auth-wrapper {
  background-color: #ffffff;
  border-radius: 5px;
  float: right;
  padding: 20px;
  width: 50vw;
}

.auth-nav .nav.nav-tabs .nav-item .nav-link {
  padding: 0 10px;
  border: none;
}
.auth-nav .nav.nav-tabs .nav-item .nav-link.active {
  border-bottom: 2px solid #415ca3;
}
.auth-nav .nav.nav-tabs .nav-item .nav-link.active:hover {
  background-color: inherit;
  color: inherit;
}
.auth-nav .nav.nav-tabs .nav-item .nav-link:hover {
  background-color: inherit;
  color: #000000 !important;
}

.text-wrapper {
  margin-top: 10px;
}

.action-buttons {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 0.7rem;
}
.action-buttons .divider {
  border-left: 1px solid rgba(22, 34, 44, 0.2862745098);
  border-right: 1px solid rgba(22, 34, 44, 0.2862745098);
  height: 1.5rem;
  margin-left: 0.5rem;
  margin-right: 1rem;
}
.action-buttons .switch-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-size: medium;
  font-weight: 500;
}
.action-buttons .switch-wrapper .switch-component input[type=checkbox] {
  display: none;
}

.btn-authgroup-side.btn.btn-outline-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  border: none;
  color: rgb(0, 144, 209);
}
.btn-authgroup-side.btn.btn-outline-secondary:hover {
  background: inherit;
  color: inherit;
}

.company-buttons .btn-authgroup.btn.btn-outline-secondary {
  background: rgb(242, 245, 248);
  color: rgb(90, 104, 114);
  font-family: "Cairo-SemiBold";
  font-size: 14px;
  border: none;
}
.company-buttons .btn-authgroup.btn.btn-outline-secondary svg {
  color: rgb(90, 104, 114);
}
.company-buttons .btn-authgroup.btn.btn-outline-secondary:hover {
  background: inherit;
  color: inherit;
}
.company-buttons .btn-authgroup.btn.btn-outline-secondary.green {
  border-radius: 20px 0px 0px 20px;
}
.company-buttons .btn-authgroup.btn.btn-outline-secondary.red {
  border-radius: 0px 20px 20px 0px;
}
.company-buttons .btn-authgroup.btn.btn-outline-secondary.selected.green {
  color: rgb(255, 255, 255);
  background: linear-gradient(-26.5650511771deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
}
.company-buttons .btn-authgroup.btn.btn-outline-secondary.selected.green svg {
  color: rgb(255, 255, 255);
}
.company-buttons .btn-authgroup.btn.btn-outline-secondary.selected.red {
  color: rgb(255, 255, 255);
  background: linear-gradient(-26.5650511771deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
}
.company-buttons .btn-authgroup.btn.btn-outline-secondary.selected.red svg {
  color: rgb(255, 255, 255);
}

.inline-search {
  display: inline-flex;
  width: 100%;
  margin-bottom: 20px;
}
.inline-search input {
  margin-right: 10px;
  max-width: 40%;
}

.btn-form-blue {
  min-width: 150px;
}
.btn-form-blue:disabled {
  background-color: #ccc !important;
  border-color: #ccc !important;
}

.btn-form-blue-cerca {
  margin-left: 0;
  background-color: #415ca3 !important;
  box-shadow: none;
  border-radius: 0px 2px 2px 0px !important;
  border: none;
}

.btn-pagine {
  width: 16px;
  color: rgb(0, 144, 209);
  background-color: white;
  border: none;
  color: gray;
  border-radius: 0;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  min-width: 12px;
  padding: 0px 3px;
  margin: 2px;
}

.btn-pagine-active {
  width: 16px;
  background-color: rgb(222, 240, 247);
  border: none;
  color: rgb(0, 144, 209);
  border-radius: 0;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  min-width: 12px;
  padding: 0px 3px;
  margin: 2px;
}
.btn-pagine-active:focus {
  border: none;
}
.btn-pagine-active:active .btn-pagine-active:active {
  border: 1px solid #415ca3;
  color: #415ca3;
  outline: 0px;
}

.btn-pagine-left {
  background-color: white;
  border: none;
  color: rgb(0, 144, 209);
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  min-width: 12px;
  padding: 3px 10px;
  margin: 2px;
}
.btn-pagine-left:focus {
  border: 1px solid #415ca3;
  color: #415ca3;
  outline: 0px;
}

.btn-pagine-right {
  background-color: white;
  border: none;
  color: rgb(0, 144, 209);
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  min-width: 12px;
  padding: 3px 10px;
  margin: 2px;
}
.btn-pagine-right:focus {
  border: 1px solid #415ca3;
  color: #415ca3;
  outline: 0px;
}

.div-catalog-verifica {
  margin-left: 15px;
  max-height: 55vh;
  overflow-y: auto;
}
.div-catalog-verifica .dropdown-menu.show {
  padding: 0px !important;
}
.div-catalog-verifica .span-cat-object-hoverable {
  padding: 10px 10px 2px 10px;
  border-color: rgb(157, 166, 173);
  border-width: 1px;
  border-style: solid;
  border-left: 0px;
  border-top: 0px;
  border-right: 0px;
  border-radius: 0px;
  font-size: 14px;
}
.div-catalog-verifica .span-cat-object-hoverable:hover {
  cursor: pointer;
}
.div-catalog-verifica .span-cat-object-hoverable .btn-group {
  width: 100%;
}
.div-catalog-verifica .span-cat-object-hoverable .btn-group button {
  color: unset !important;
  text-align: left;
}
.div-catalog-verifica .span-cat-object-hoverable .btn-group button:active:hover {
  box-shadow: none;
}
.div-catalog-verifica .span-cat-object-hoverable svg {
  margin-right: 10px;
}
.div-catalog-verifica .span-cat-object-hoverable.level1 {
  font-weight: bold;
  margin-left: unset;
}
.div-catalog-verifica .span-cat-object-hoverable.level2 {
  margin-left: 50px;
}
.div-catalog-verifica .span-cat-object-hoverable.level3 {
  margin-left: 60px;
}
.catalog-title-verifica {
  color: rgb(0, 80, 117);
  font-family: "Cairo-SemiBold";
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
}

.button-user-catalog {
  background-color: #ffffff;
  border: none;
}
.button-user-catalog:hover {
  background-color: #ffffff;
  border: none;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
  border-right-color: #ffffff !important;
}

.overlay {
  position: absolute; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4980392157); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
}

.dropdown-menu.show .dropdown-item:not(:last-child) {
  border-bottom: none;
}

.rst__lineBlock.rst__lineHalfHorizontalRight.rst__lineFullVertical {
  display: none;
}

.rst__lineBlock.rst__lineFullVertical {
  display: none;
}

.rst__lineChildren {
  display: none;
}

.rst__lineBlock.rst__lineHalfHorizontalRight {
  display: none;
}

.rst__row {
  border: none;
}

.rst__lineChildren {
  display: none !important;
}

.rst__rowWrapper {
  padding: 0 !important;
}

.drop-item-consent {
  width: 88px;
  height: 16px;
  color: rgb(0, 144, 209);
  font-size: 16px;
  font-family: Roboto-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.2px;
  line-height: 16px;
}

.card-download {
  width: 100%;
  border-width: 0px;
  display: inline-flex;
  /* height: 160px; */
}
.card-download .body {
  display: inline-flex;
  background-color: rgba(254, 254, 255, 0.5);
}
.card-download .body .div-logo {
  padding: 0px 20px;
  line-height: 150px;
  min-width: 250px;
}
.card-download .body .div-description {
  padding: 30px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.card-download .body .div-description a {
  cursor: pointer;
  text-decoration: underline;
}
.card-download .body .div-description .span-os {
  font-weight: bold;
  margin-right: 20px;
}
.card-download .body .div-description .span-versione {
  margin: 10px;
}
.card-download .div-download {
  text-align: center;
  background-color: rgb(242, 245, 248);
  padding: 50px;
  min-width: 300px;
}
.card-download .div-download button {
  width: 200px;
}
.card-download .div-download-under-maintenance {
  text-align: center;
  background-color: rgb(242, 245, 248);
  padding-top: 20px;
  min-width: 300px;
  max-width: 300px;
}
.card-download .div-download-under-maintenance button {
  width: 200px;
}

.qlik-history-link {
  padding: 0px 0px 0px 20px;
}
.qlik-history-link a {
  cursor: pointer;
  text-decoration: underline;
}

.qlik-history {
  padding: 20px;
}
.qlik-history h4 {
  margin-bottom: 20px;
}
.qlik-history a {
  cursor: pointer;
  text-decoration: underline;
}
.qlik-history .span-os {
  font-weight: bold;
  margin-right: 20px;
}
.qlik-history .span-versione {
  margin: 10px;
}

@font-face {
  font-family: "Cairo-Semibold";
  src: url("./font/Cairo-SemiBold.ttf");
  font-weight: normal;
  font-style: normal;
}
body {
  color: rgba(0, 0, 0, 0.65);
  font-family: "Roboto", sans-serif;
  background-color: #f2f5f8 !important;
}

a {
  color: #0091dc;
}

.app, .app-dashboard, .app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-header.navbar {
  display: flex;
}

.app-header * a {
  color: #fff;
}

.app-header * a:hover {
  text-decoration: none;
  color: rgb(0, 153, 255);
}

.app-header.navbar .navbar-brand {
  text-align: left;
  font-size: 26px;
  flex: 2;
  padding: 15px 0 0 0;
  vertical-align: middle;
  line-height: 24px;
  color: #fff;
}

.sidebar-fixed .sidebar {
  height: 100vh;
}

.header-fixed .app-body {
  margin-top: 64px;
  margin-bottom: 0;
}

.header-fixed .app-header {
  position: fixed;
  z-index: 1020;
  width: 100%;
}

.app-body .app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;
}
.app-body .app-body .main {
  flex: 1;
  min-width: 0;
}

.card-body, .card-block {
  padding: 15px;
}

.navbar {
  align-items: unset;
}

.breadcrumb-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991.98px) {
  .app-footer, .main {
    margin-left: 0 !important;
  }
}
.container-fluid {
  margin-top: 55px !important;
  padding: 0;
  width: 100%;
}

@media (max-width: 768px) {
  .container-fluid {
    margin-top: 10px !important;
    padding: 0 12px !important;
  }
}
.sidebar-minimized.sidebar-fixed .main, .sidebar-minimized.sidebar-fixed .app-footer {
  margin-left: 64px;
}

.sidebar-fixed .main, .sidebar-minimized.sidebar-fixed .app-footer {
  margin-left: 220px;
}

.aside-menu {
  transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
}

.btn-primary-viola {
  border-radius: 4px;
  width: 100px;
  background-color: #415ca3;
  border: 1px solid #415ca3;
  padding: 8px;
  font-size: 14px;
  transition: opacity 0.15s ease-in-out;
}
.btn-primary-viola:hover {
  opacity: 0.8;
  background-color: #415ca3;
  border: 1px solid #415ca3;
}
.btn-primary-viola:active {
  background-color: #415ca3 !important;
  border: 1px solid #415ca3 !important;
}
.btn-primary-viola:focus {
  box-shadow: 0 0 0 0 !important;
}

.btn-secondario {
  background-color: #ffffff;
  border: 1px solid #415ca3;
  color: #415ca3;
  border-radius: 4px;
  height: 32px;
  padding-top: 4px;
}
.btn-secondario:hover {
  opacity: 0.8;
  background-color: #ffffff;
  border: 1px solid #415ca3;
  color: #415ca3;
}

.btn-secondario-danger {
  background-color: #ffffff;
  border: none;
  color: #da5848;
  height: 32px;
}
.btn-secondario-danger.small {
  height: 28px;
  padding: 0px;
  font-size: 13px;
}
.btn-secondario-danger:hover {
  opacity: 0.8;
  background-color: #ffffff;
  border: none;
  color: #da5848;
}
.btn-secondario-danger:active {
  color: inherit !important;
}
.btn-secondario-danger:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn-modifica {
  background-color: #ffffff;
  border: none;
  color: rgb(0, 144, 209);
  border-radius: 4px;
  height: 32px;
}
.btn-modifica:hover {
  opacity: 0.8;
  background-color: #ffffff;
  border: none;
  color: #415ca3;
}
.btn-modifica:focus {
  color: rgb(0, 144, 209);
  background: inherit;
  outline: none;
  box-shadow: none !important;
}
.btn-modifica:active {
  color: #415ca3;
}
.btn-modifica:disabled {
  background-color: #ffffff;
  border: none;
  color: #D9D9D9;
  border-radius: 4px;
  height: 32px;
}

.btn-primary {
  background: linear-gradient(-26.5650511771deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
  border-radius: 2px;
  border: 0;
}

.btn-secondary {
  border-radius: 2px;
}

.btn-annulla {
  background: rgb(242, 245, 248);
  color: rgb(0, 144, 209);
  border: 1px solid rgb(0, 144, 209);
}
.btn-annulla:hover {
  border: 1px solid rgb(0, 144, 209);
  color: rgb(0, 144, 209);
}

.card-container {
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
  /* padding:3px 0; */
  background: #fff;
  margin: 12px 0px;
}
.card-container .card-body {
  padding: 24px;
}
@media (min-width: 768px) {
  .card-container {
    margin: 0;
  }
  .card-container:hover {
    transition: all 0.3s ease-in-out;
  }
  .card-container:hover .but-kpi-dettagli {
    transition: all 0.3s ease-in-out;
    opacity: 1;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .card-container {
    margin: 12px 0;
  }
}
.card-container .but-kpi-dettagli {
  transition: all 0.3s ease-in-out;
}
@media (min-width: 768px) {
  .card-container .but-kpi-dettagli {
    opacity: 0;
  }
}
.card-container .but-kpi-dettagli .btn-secondary {
  background-color: #ffffff;
  color: rgb(0, 144, 209);
  border: none;
}
.card-container .but-kpi-dettagli .btn-secondary:focus {
  box-shadow: none !important;
  outline: none !important;
}

.nav-tabs {
  border: 1px solid rgb(201, 217, 232);
  background-color: #fff !important;
}
.nav-tabs .nav-item a.nav-link {
  cursor: pointer;
  padding: 15px !important;
  border-bottom: 5px solid #fff !important;
  background: #fff;
  border-radius: 0 !important;
}
.nav-tabs .nav-item a.nav-link.active {
  background-color: rgb(242, 245, 248) !important;
  border-bottom: 5px solid #0090d1 !important;
  color: rgb(0, 144, 209) !important;
}
.nav-tabs .nav-item a.nav-link.active:hover {
  background: rgb(242, 245, 248) !important;
}
.nav-tabs .nav-item a.nav-link:hover {
  background-color: #fff !important;
  color: inherit !important;
}
.nav-tabs .nav-item {
  width: 30%;
  text-align: center;
  margin-bottom: 0;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 2px;
  border-color: #0090d1;
}

.modal-header {
  border-bottom: 1px solid #0090d1;
  color: rgb(0, 80, 117);
  font-family: "Cairo-SemiBold";
  font-size: 24px;
}
.modal-header .close {
  color: rgb(0, 144, 209);
  font-size: 30px;
  opacity: 1;
}

.modal-footer {
  background: rgb(242, 245, 248);
}

.label-form-inviti {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
}

.modal-body .form-group {
  margin-bottom: 0;
}
.modal-body p {
  margin-bottom: 0;
}

.div-numero-licenze .dropdown-item {
  padding: 0 10px;
}
.div-numero-licenze .dropdown-menu.show .dropdown-item:not(:last-child) {
  width: 100%;
}
.div-numero-licenze .dropdown-menu.show .dropdown-item span:nth-child(1) {
  display: block;
  float: left;
}
.div-numero-licenze .dropdown-menu.show .dropdown-item span:nth-child(2) {
  display: block;
  float: right;
}

.input-cerca {
  display: inline;
  width: 80%;
  padding: 18px 10px;
  font-size: 14px;
  background: rgb(242, 245, 248);
  color: rgb(193, 193, 196);
}
.input-cerca:focus {
  color: inherit;
  box-shadow: none;
}

.dropdown-licenze .dropdown-item {
  width: 270px;
}

.container-wip {
  display: 1 1 auto;
  text-align: -webkit-center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  padding: 32px;
  background-color: white;
  border-radius: 0px;
  box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.64);
}
@media (max-width: 767px) {
  .container-wip {
    width: 80%;
  }
}

.div-img-wip {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  text-align: center;
  margin: 3% 0;
}

.img-wip {
  max-width: 100%;
  height: auto;
  width: 220px;
}

.img-wip-secondary {
  width: 180px;
  margin-bottom: 5%;
}

.div-text-wip {
  text-align: center;
  margin: auto;
  max-width: 775px;
  font-size: 35px;
  padding: 70px 45px 30px 45px;
  font-weight: 700px;
  color: #eeeff4 !important;
  margin-top: -3px;
  letter-spacing: normal;
  border-radius: 0px 0px 5px 5px;
}
@media (max-width: 768px) {
  .div-text-wip {
    font-size: 24px;
    border-radius: 0px 0px 8px 8px;
  }
}

.div-text-errorpage {
  text-align: center;
  margin: auto;
  max-width: 775px;
  font-size: 20px;
  padding: 25px;
  margin-top: -3px;
  letter-spacing: -0.5px;
  border-radius: 0px 0px 5px 5px;
}
@media (max-width: 768px) {
  .div-text-errorpage {
    font-size: 24px;
    border-radius: 0px 0px 8px 8px;
  }
}

.div-bottoni-errorpage {
  padding-top: 40px;
  color: #0090d1 !important;
  font-size: 18px;
  font-weight: bold;
}
.div-bottoni-errorpage .btn-redirect-login {
  margin-right: 15px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #415ca3;
  width: 120px;
  height: 45px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .div-bottoni-errorpage .btn-redirect-login {
    font-size: 16px;
  }
}
.div-bottoni-errorpage .btn-redirect-login:hover {
  opacity: 0.8;
}
.div-bottoni-errorpage .btn-redirect-login:active {
  background-color: #ffffff !important;
  border-radius: 4px !important;
  color: #415ca3 !important;
}
.div-bottoni-errorpage p {
  cursor: pointer;
}
.div-bottoni-errorpage .btn-contact-us {
  margin-left: 15px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #415ca3;
  color: #415ca3;
  width: 120px;
  height: 45px;
  font-weight: 600;
  font-size: 14px;
}
.div-bottoni-errorpage .btn-contact-us:hover {
  opacity: 0.8;
}
.div-bottoni-errorpage .btn-contact-us:active {
  background-color: #ffffff !important;
  border-radius: 4px !important;
  color: #415ca3 !important;
}
@media (max-width: 768px) {
  .div-bottoni-errorpage .btn-contact-us {
    font-size: 16px;
  }
}

#invitation-accept {
  width: 40%;
  background: #fff;
  margin: 0 auto;
  text-align: left;
  margin-top: 80px;
}
#invitation-accept .div-img-wip {
  text-align: left;
  padding: 80px;
  margin: 0;
}
#invitation-accept .div-img-wip img {
  margin-bottom: 40px;
  object-position: -40px;
  width: 300px;
}
#invitation-accept .div-img-wip h2 {
  font-family: "Cairo-SemiBold";
  font-size: 24px;
  color: rgb(0, 80, 117);
}
#invitation-accept .div-img-wip p {
  font-size: 16px;
}
#invitation-accept .div-img-wip button {
  background: linear-gradient(-26.5650511771deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
  border-radius: 2px;
  height: 48px;
  width: 100%;
  color: rgb(255, 255, 255);
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  border: none;
}
#invitation-accept .div-img-wip button.secondary {
  height: 48px;
  background: #fff;
  border: 1px solid rgb(0, 144, 209);
  border-radius: 2px;
  color: rgb(0, 144, 209);
}
#invitation-accept .div-img-wip p.mid-line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgb(90, 104, 114);
  line-height: 0.1em;
  margin: 40px 0;
  color: rgb(90, 104, 114);
}
#invitation-accept .div-img-wip p.mid-line span {
  padding: 20px 0;
  background: #fff;
  padding: 0 10px;
}

.p-top-20 {
  padding-top: 20px;
}