.rst__row {
  height: 90%;
  min-width: 300px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;

  .rst__moveHandle {
    width: 30px;
  }
  .rst__rowTitle {
    font-weight: normal;
  }

  .rst__moveHandle, .rst__loadingHandle {
    outline: none;
    border: 0;
  }

  .rst__rowContents {
    padding-left: 5px;
    box-shadow:none;
    -webkit-box-shadow: none;
    // min-width: 300px;
  }

  &.INVITE {
    min-width: 350px;
    // border:1px solid rgb(0, 144, 209);
    .rst__rowContents {

    }
    & .span-icona {
        margin-right: 20px;
    }
    .rst__moveHandle, .rst__loadingHandle {
      background: #ffffff url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTVweCIgaGVpZ3RoPSIyMHB4IiBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJncmlwLWxpbmVzIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtZ3JpcC1saW5lcyBmYS13LTIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0icmdiKDAsIDE0NCwgMjA5KSIgZD0iTTQ5NiAyODhIMTZjLTguOCAwLTE2IDcuMi0xNiAxNnYzMmMwIDguOCA3LjIgMTYgMTYgMTZoNDgwYzguOCAwIDE2LTcuMiAxNi0xNnYtMzJjMC04LjgtNy4yLTE2LTE2LTE2em0wLTEyOEgxNmMtOC44IDAtMTYgNy4yLTE2IDE2djMyYzAgOC44IDcuMiAxNiAxNiAxNmg0ODBjOC44IDAgMTYtNy4yIDE2LTE2di0zMmMwLTguOC03LjItMTYtMTYtMTZ6Ij48L3BhdGg+PC9zdmc+') no-repeat center ;
      // border-left: solid #bbb 1px;
      // border-top: solid #bbb 1px;
      // border-bottom: solid #bbb 1px;
      box-shadow:none;
      -webkit-box-shadow: none;
    }
    &:hover {
      border: 1px solid rgb(0, 154, 218) !important;
    }
    &.active {
      .rst__rowContents {
        background-color: rgb(222, 240, 247) !important;
        color: rgb(0, 154, 218);
      }
      .rst__moveHandle, .rst__loadingHandle {
        background: rgb(222, 240, 247) url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTVweCIgaGVpZ3RoPSIyMHB4IiBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJncmlwLWxpbmVzIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtZ3JpcC1saW5lcyBmYS13LTIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0icmdiKDAsIDE0NCwgMjA5KSIgZD0iTTQ5NiAyODhIMTZjLTguOCAwLTE2IDcuMi0xNiAxNnYzMmMwIDguOCA3LjIgMTYgMTYgMTZoNDgwYzguOCAwIDE2LTcuMiAxNi0xNnYtMzJjMC04LjgtNy4yLTE2LTE2LTE2em0wLTEyOEgxNmMtOC44IDAtMTYgNy4yLTE2IDE2djMyYzAgOC44IDcuMiAxNiAxNiAxNmg0ODBjOC44IDAgMTYtNy4yIDE2LTE2di0zMmMwLTguOC03LjItMTYtMTYtMTZ6Ij48L3BhdGg+PC9zdmc+') no-repeat center ;
        box-shadow:none;
        border-left: 2px solid #0090d1;
      }
    }
  }

  &.entryGroup {
    color:rgb(0, 80, 117);
    .rst__rowContents {
      border: 0;
            &:not(:hover) {
        .btn-group {
          display: none;
        }
      }
      &:hover {
        .btn-group {
          display: block;
        }
      }
    }
    .rst__moveHandle {
      display: none;
    }

    .rst__moveHandle, .rst__loadingHandle {
      background: #151D34 url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTVweCIgaGVpZ3RoPSIyMHB4IiBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJncmlwLWxpbmVzIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtZ3JpcC1saW5lcyBmYS13LTIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0icmdiKDAsIDE0NCwgMjA5KTsiIGQ9Ik00OTYgMjg4SDE2Yy04LjggMC0xNiA3LjItMTYgMTZ2MzJjMCA4LjggNy4yIDE2IDE2IDE2aDQ4MGM4LjggMCAxNi03LjIgMTYtMTZ2LTMyYzAtOC44LTcuMi0xNi0xNi0xNnptMC0xMjhIMTZjLTguOCAwLTE2IDcuMi0xNiAxNnYzMmMwIDguOCA3LjIgMTYgMTYgMTZoNDgwYzguOCAwIDE2LTcuMiAxNi0xNnYtMzJjMC04LjgtNy4yLTE2LTE2LTE2eiI+PC9wYXRoPjwvc3ZnPg==') no-repeat center ;
      box-shadow:none;
    }
    &:hover {
      border: 1px solid rgb(0, 154, 218) !important;
    }
    &.active {
      .rst__rowContents {
        background-color: rgb(222, 240, 247) !important;
        color: rgb(0, 154, 218);
        border-left: 2px solid #0090d1 !important;

      }
      .rst__moveHandle, .rst__loadingHandle {
        background: rgb(222, 240, 247) url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTVweCIgaGVpZ3RoPSIyMHB4IiBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJncmlwLWxpbmVzIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtZ3JpcC1saW5lcyBmYS13LTIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0icmdiKDAsIDE0NCwgMjA5KSIgZD0iTTQ5NiAyODhIMTZjLTguOCAwLTE2IDcuMi0xNiAxNnYzMmMwIDguOCA3LjIgMTYgMTYgMTZoNDgwYzguOCAwIDE2LTcuMiAxNi0xNnYtMzJjMC04LjgtNy4yLTE2LTE2LTE2em0wLTEyOEgxNmMtOC44IDAtMTYgNy4yLTE2IDE2djMyYzAgOC44IDcuMiAxNiAxNiAxNmg0ODBjOC44IDAgMTYtNy4yIDE2LTE2di0zMmMwLTguOC03LjItMTYtMTYtMTZ6Ij48L3BhdGg+PC9zdmc+') no-repeat center ;
        box-shadow:none;
        border-left: 2px solid #0090d1 !important;
      }
    }
  }

  &.GROUP {
    color: rgb(21, 41, 53);
    background: none;
    font-size:16px;
    // border:1px solid rgb(0, 144, 209);
    .rst__rowContents {
      border: 0;
      border-radius: 0;

      &:not(:hover) {
        .btn-group {
          display: none;
        }
      }
      &:hover {
        .btn-group {
          display: block;
        }
      }
    }
    .rst__moveHandle, .rst__loadingHandle {
      background: #fff url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTVweCIgaGVpZ3RoPSIyMHB4IiBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJncmlwLWxpbmVzIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtZ3JpcC1saW5lcyBmYS13LTIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0icmdiKDAsIDE0NCwgMjA5KSIgZD0iTTQ5NiAyODhIMTZjLTguOCAwLTE2IDcuMi0xNiAxNnYzMmMwIDguOCA3LjIgMTYgMTYgMTZoNDgwYzguOCAwIDE2LTcuMiAxNi0xNnYtMzJjMC04LjgtNy4yLTE2LTE2LTE2em0wLTEyOEgxNmMtOC44IDAtMTYgNy4yLTE2IDE2djMyYzAgOC44IDcuMiAxNiAxNiAxNmg0ODBjOC44IDAgMTYtNy4yIDE2LTE2di0zMmMwLTguOC03LjItMTYtMTYtMTZ6Ij48L3BhdGg+PC9zdmc+') no-repeat center ;
      box-shadow:none;
      -webkit-box-shadow: none;
    }
    &.active {
      .rst__rowContents {
        background-color: rgb(222, 240, 247) !important;
        color: rgb(0, 154, 218);
      }
      .rst__moveHandle, .rst__loadingHandle {
        background: rgb(222, 240, 247) url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTVweCIgaGVpZ3RoPSIyMHB4IiBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJncmlwLWxpbmVzIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtZ3JpcC1saW5lcyBmYS13LTIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0icmdiKDAsIDE0NCwgMjA5KSIgZD0iTTQ5NiAyODhIMTZjLTguOCAwLTE2IDcuMi0xNiAxNnYzMmMwIDguOCA3LjIgMTYgMTYgMTZoNDgwYzguOCAwIDE2LTcuMiAxNi0xNnYtMzJjMC04LjgtNy4yLTE2LTE2LTE2em0wLTEyOEgxNmMtOC44IDAtMTYgNy4yLTE2IDE2djMyYzAgOC44IDcuMiAxNiAxNiAxNmg0ODBjOC44IDAgMTYtNy4yIDE2LTE2di0zMmMwLTguOC03LjItMTYtMTYtMTZ6Ij48L3BhdGg+PC9zdmc+') no-repeat center ;
        box-shadow:none;
        border-left: 2px solid #0090d1;
      }
    }
    &.default {
      font-style: italic;
    }
    &:hover {
      border: 1px solid rgb(0, 154, 218) !important;
    }
    &.edit {
      .rst__rowContents {
        background-color: #ffffff;
        color: white;
        border: 0;
        border-radius: 0;
        .rst__rowLabel {
          display: none;
        }
      }
      .rst__rowToolbar {
        & input {
          margin-right: 10px;
          background-color: rgb(242, 245, 248);
          border: 1px solid rgb(0, 144, 209);
        }
        & button {
          height: 32px;
          width: 68px;
          color: #ffffff;
          background: inherit;
          background: linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
          border-radius: 2px; 
          border: none;
        }
      }
    }
  }

  &.USER {
    // border:1px solid rgb(0, 144, 209);
    min-width: 350px;
    .rst__rowContents {
      &:not(:hover) {
        svg {
          display: none;
        }
      }
      &:hover {
        svg {
          display: block;
        }
      }
    }
    .rst__moveHandle, .rst__loadingHandle {
      background: #ffffff url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTVweCIgaGVpZ3RoPSIyMHB4IiBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJncmlwLWxpbmVzIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtZ3JpcC1saW5lcyBmYS13LTIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0icmdiKDAsIDE0NCwgMjA5KSIgZD0iTTQ5NiAyODhIMTZjLTguOCAwLTE2IDcuMi0xNiAxNnYzMmMwIDguOCA3LjIgMTYgMTYgMTZoNDgwYzguOCAwIDE2LTcuMiAxNi0xNnYtMzJjMC04LjgtNy4yLTE2LTE2LTE2em0wLTEyOEgxNmMtOC44IDAtMTYgNy4yLTE2IDE2djMyYzAgOC44IDcuMiAxNiAxNiAxNmg0ODBjOC44IDAgMTYtNy4yIDE2LTE2di0zMmMwLTguOC03LjItMTYtMTYtMTZ6Ij48L3BhdGg+PC9zdmc+') no-repeat center ;
      // border-left: solid #bbb 1px;
      // border-top: solid #bbb 1px;
      // border-bottom: solid #bbb 1px;
      box-shadow:none;
      -webkit-box-shadow: none;
    }
    &:hover {
      border: 1px solid rgb(0, 154, 218) !important;
    }
    &.active {
      .rst__rowContents {
        background-color: rgb(222, 240, 247) !important;
        color: rgb(0, 154, 218);
      }
      .rst__moveHandle, .rst__loadingHandle {
        background: rgb(222, 240, 247) url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTVweCIgaGVpZ3RoPSIyMHB4IiBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJncmlwLWxpbmVzIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtZ3JpcC1saW5lcyBmYS13LTIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0icmdiKDAsIDE0NCwgMjA5KSIgZD0iTTQ5NiAyODhIMTZjLTguOCAwLTE2IDcuMi0xNiAxNnYzMmMwIDguOCA3LjIgMTYgMTYgMTZoNDgwYzguOCAwIDE2LTcuMiAxNi0xNnYtMzJjMC04LjgtNy4yLTE2LTE2LTE2em0wLTEyOEgxNmMtOC44IDAtMTYgNy4yLTE2IDE2djMyYzAgOC44IDcuMiAxNiAxNiAxNmg0ODBjOC44IDAgMTYtNy4yIDE2LTE2di0zMmMwLTguOC03LjItMTYtMTYtMTZ6Ij48L3BhdGg+PC9zdmc+') no-repeat center ;
        box-shadow:none;
        border-left: 2px solid #0090d1;
      }
    }
  }
}

.rst__rowContents {
  border: none !important;
}

.auth-header {
  color: rgb(0, 80, 117);
  font-family: Cairo-SemiBold;
  h4 {
      font-size: 16px;
  }
}
.side-view {
  background-color: white;
  position: fixed;
  height: calc(100vh - 120px);
  margin-top: 120px;
  width: 0px;
  top: 0;
  right: 0;
  -webkit-box-shadow: -4px 0px 6px -2px rgba(89,89,89,1);
  -moz-box-shadow: -4px 0px 6px -2px rgba(89,89,89,1);
  box-shadow: -4px 0px 6px -2px rgba(89,89,89,1);
  transition: width 0.5s, visibility 0.5s, box-shadow 0.5s;
  visibility: hidden;
  z-index: 101;

  .auth-header-wrapper {
    border-width: 0px 0px 1px;
    border-style: solid;
    border-color: rgb(217, 217, 217);
    text-align: center;
    text-transform: uppercase;
    border-bottom: 2px solid #0090d1;
    .auth-header {
      margin: 20px;
      .h2 {
        font-size: "20px";
        font-weight: 700
      }
    }
  }

  .container {
    padding: 0px!important;
    padding-bottom: 10px;
    margin-bottom: 30px;
    margin-top: 0px;
    .auth-header {
      border-bottom: 1px solid rgba(217, 217, 217, 1);
    }

    .input-wrapper {
      display: flex;
      padding: 30px 0;
      width: 100%;
      input {
        max-width: 80%;
      }
      button {
        background-color: #415ca3 !important;
      }
    }

    @media (min-height: 1200px)  {
      .div-catalog {
        max-height: 60vh !important;
      }
    }

    @media (min-height: 920px) and (max-height: 1190px)  {
      .div-catalog {
        max-height: 55vh !important;
      }
    }

    @media (min-height: 720px) and (max-height: 900px)  {
      .div-catalog {
        max-height: 50vh !important;
      }
    }

    .div-catalog {
      margin-top: 30px;
      margin-left: 15px;
      max-height: 40vh;
      overflow-y: auto;
      .catalog-title {
        background-color: rgba(250, 250, 250, 1);
        border-bottom: 1px solid rgba(233, 233, 233, 1);
        padding: 10px;
        color: rgb(0, 80, 117);
        font-family: Cairo-SemiBold;
        font-size: 16px;
        font-weight: 600;
      }
      .dropdown-menu.show {
        padding: 0px !important;
      }
      .span-cat-object {
        padding: 10px 10px 10px 10px;
        border-color: rgb(157, 166, 173);
        border-width: 1px;
        border-style: solid;
        border-left: 0px;
        border-top: 0px;
        border-right: 0px;
        border-radius: 0px;
        & .btn-group {
          width: 100%;
          button {
            color: unset !important;
            text-align: left;
            &:active:hover {
              box-shadow: none;
            }
          }
        }
        svg {
          margin-right: 10px;
        }
        &.level0 {
            font-weight: bold !important;
            //   margin-left: unset;
        }
        &.level1 {
            font-weight: bold !important;
        }
        // &.level1 {
        //   font-weight: bold;
        //   margin-left: unset;
        // }
        // &.level2 {
        //   margin-left: 20px;
        // }
        // &.level3 {
        //   margin-left: 40px;
        // }
      }
    }
  }
}

.input-form {
  .error {
    outline: red;
  }
}

.side-view-overlay {
  background-color: rgba(169, 169, 169, 0.30);
  position: fixed;
  width: 100%;
  height: calc(100vh - 55px);
  top: 0;
  left: 0;
  transition: opacity 0.5s, visibility 0.5s;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
}

.side-view-show {
  visibility: visible;
  width: 800px;
}

.side-view-overlay-show {
  visibility: visible;
  opacity: 0.3;
  height: 100vh;
  background-color: rgba(0, 0, 0, 1)
}

@media screen and (max-width: 800px) {
  .side-view-show {
    width: 100%;
  }

  .side-view-overlay {
    width: 0;
  }
}

.btn-group > button {
  background: inherit;
  border: none;
}

.btn-group > button  {
  &:hover {
    background: inherit;
    border: none;
  }
}

.btn-group > button  {
  &:not(:disabled):not(.disabled):active {
    background-color: inherit !important;
    color: #fff !important;
    border: none;
  }
}

.btn-group > button  {
  &:focus {
    background-color: inherit !important;
    color: #fff !important;
    border: none;
    box-shadow: none;
  }
}

.rst__toolbarButton {
  .dropdown-menu.show .dropdown-item:not(:last-child) {
    width: 100%;
  }
}

.rst__nodeContent {
  .rst__collapseButton {
    background: #fff url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaGV2cm9uLWRvd24iIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1jaGV2cm9uLWRvd24gZmEtdy0xNCIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik0yMDcuMDI5IDM4MS40NzZMMTIuNjg2IDE4Ny4xMzJjLTkuMzczLTkuMzczLTkuMzczLTI0LjU2OSAwLTMzLjk0MWwyMi42NjctMjIuNjY3YzkuMzU3LTkuMzU3IDI0LjUyMi05LjM3NSAzMy45MDEtLjA0TDIyNCAyODQuNTA1bDE1NC43NDUtMTU0LjAyMWM5LjM3OS05LjMzNSAyNC41NDQtOS4zMTcgMzMuOTAxLjA0bDIyLjY2NyAyMi42NjdjOS4zNzMgOS4zNzMgOS4zNzMgMjQuNTY5IDAgMzMuOTQxTDI0MC45NzEgMzgxLjQ3NmMtOS4zNzMgOS4zNzItMjQuNTY5IDkuMzcyLTMzLjk0MiAweiI+PC9wYXRoPjwvc3ZnPg==") no-repeat center !important;
    color: rgb(21, 41, 53);
    border: none !important;
    border-radius: unset !important;
    box-shadow: none !important;
  }

  .rst__expandButton {
    background: #fff url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaGV2cm9uLXJpZ2h0IiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY2hldnJvbi1yaWdodCBmYS13LTIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzIwIDUxMiI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMjg1LjQ3NiAyNzIuOTcxTDkxLjEzMiA0NjcuMzE0Yy05LjM3MyA5LjM3My0yNC41NjkgOS4zNzMtMzMuOTQxIDBsLTIyLjY2Ny0yMi42NjdjLTkuMzU3LTkuMzU3LTkuMzc1LTI0LjUyMi0uMDQtMzMuOTAxTDE4OC41MDUgMjU2IDM0LjQ4NCAxMDEuMjU1Yy05LjMzNS05LjM3OS05LjMxNy0yNC41NDQuMDQtMzMuOTAxbDIyLjY2Ny0yMi42NjdjOS4zNzMtOS4zNzMgMjQuNTY5LTkuMzczIDMzLjk0MSAwTDI4NS40NzUgMjM5LjAzYzkuMzczIDkuMzcyIDkuMzczIDI0LjU2OC4wMDEgMzMuOTQxeiI+PC9wYXRoPjwvc3ZnPg==") no-repeat center !important;
    color: rgb(21, 41, 53);
    border: none !important;
    border-radius: unset !important;
    box-shadow: none !important;
  }
}

.auth-main-page {
  background: #fff;
  max-width: inherit !important;
  display: flex;
  align-items: flex-start;
  .tree-container {
    max-width: inherit !important;
    padding:20px;
    width: 45%;
    margin-right: 20px;
    height: calc(100vh - 64px - 56px);
    // width: 40vw;
    .rst__tree {
      max-width: 90% !important;
      height:90%!important;
      // float: left;
    }
    .create-group {
      border: 1px solid rgb(0, 144, 209);
      background-color: #fff;
      color: rgb(0, 144, 209);
      font-size: 16px;
      font-weight: 500;
      width: 100%;
      margin-top: 20px;
      & svg {
        margin-right: 10px;
      }
      &:focus, &:active {
        border: 1px solid rgb(0, 144, 209);
        color:rgb(0, 144, 209);
      }
    }
  }
  .company-auth-wrapper {
    background-color: #ffffff;
    border-radius: 5px;
    float: right;
    padding: 20px;
    width: 50vw;
  }
}

.auth-nav .nav.nav-tabs .nav-item .nav-link {
  padding: 0 10px;
  border: none;
  &.active {
    border-bottom: 2px solid #415ca3;
    &:hover {
      background-color: inherit;

      color: inherit;
    }
  }
  &:hover {
    background-color: inherit;

    color: #000000 !important;
  }
}
.text-wrapper {
  margin-top: 10px;
}

.action-buttons{
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 0.7rem;
  .divider{
    border-left:1px solid #16222c49; 
    border-right:1px solid #16222c49; 
    height:1.5rem;
    margin-left: 0.5rem;
    margin-right: 1rem;
  }
  .switch-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-size: medium;
    font-weight: 500;
    .switch-component{
      input[type="checkbox"]{
        display: none;
      }
    }
  }
}


.btn-authgroup-side.btn.btn-outline-secondary {
    &:hover {
    background: inherit;
    color: inherit;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  border: none;
  color: rgb(0, 144, 209);
}

.company-buttons .btn-authgroup.btn.btn-outline-secondary {
  background: rgb(242, 245, 248);
  color: rgb(90, 104, 114);
  font-family: 'Cairo-SemiBold';
  font-size: 14px;
  border: none;
  svg {
    color: rgb(90, 104, 114);
  }

  &:hover {
    background: inherit;
    color: inherit;
  }

  &.green {
    border-radius: 20px 0px 0px 20px;
  }
  &.red {
    border-radius: 0px 20px 20px 0px;
  }

  &.selected.green {
    color: rgb(255, 255, 255);
    background: linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
    svg {
      color: rgb(255, 255, 255);
    }
  }

  &.selected.red {
    color: rgb(255, 255, 255);
    background: linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
    svg {
      color: rgb(255, 255, 255);
    }
  }
}

.inline-search {
  display: inline-flex;
  width: 100%;
  margin-bottom: 20px;
  & input {
    margin-right: 10px;
    max-width: 40%;
  }
}

.btn-form-blue {
  min-width: 150px;
  &:disabled{
    background-color: #ccc !important;
    border-color: #ccc !important;
  }
}

.btn-form-blue-cerca {
  margin-left: 0;
  background-color: #415ca3 !important;
  box-shadow: none;
  border-radius: 0px 2px 2px 0px!important;
  border: none;
}

.btn-pagine {
  width: 16px;
  color: rgb(0, 144, 209);
  background-color: white;
  border: none;
  color: gray;
  border-radius: 0;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  min-width: 12px;
  padding: 0px 3px;
  margin: 2px;
}

.btn-pagine-active {
  width: 16px;
  background-color: rgb(222, 240, 247);
  border: none;
  color: rgb(0, 144, 209);
  border-radius: 0;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  min-width: 12px;
  padding: 0px 3px;
  margin: 2px;
  &:focus {
    border:none;
  }
  &:active {
    .btn-pagine-active:active {
      border: 1px solid #415ca3;
      color: #415ca3;
      outline: 0px
    }
  }
}

.btn-pagine-left {
  background-color: white;
  border: none;
  color: rgb(0, 144, 209);
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  min-width: 12px;
  padding: 3px 10px;
  margin: 2px;
  &:focus {
    border: 1px solid #415ca3;
    color: #415ca3;
    outline: 0px
  }
}

.btn-pagine-right {
  background-color: white;
  border: none;
  color: rgb(0, 144, 209);
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  min-width: 12px;
  padding: 3px 10px;
  margin: 2px;

  &:focus {
    border: 1px solid #415ca3;
    color: #415ca3;
    outline: 0px
  }
}

    .div-catalog-verifica {
      margin-left: 15px;
      max-height: 55vh;
      overflow-y: auto;

      .dropdown-menu.show {
        padding: 0px !important;
      }
        .span-cat-object-hoverable {
            &:hover {
                cursor: pointer;
            }
            padding: 10px 10px 2px 10px;
            border-color:rgb(157, 166, 173);
            border-width: 1px;
            border-style: solid;
            border-left: 0px;
            border-top: 0px;
            border-right: 0px;
            border-radius: 0px;
            font-size: 14px;
            & .btn-group {
                width: 100%;
                button {
                    color: unset !important;
                    text-align: left;
                    &:active:hover {
                    box-shadow: none;
                    }
                }
            }
            svg {
            margin-right: 10px;
            }
            &.level1 {
            font-weight: bold;
            margin-left: unset;
            }
            &.level2 {
            margin-left: 50px;
            }
            &.level3 {
            margin-left: 60px;
            }
            &.selected {

            }
        }
    }
    .catalog-title-verifica {
        color: rgb(0, 80, 117);
        font-family: 'Cairo-SemiBold';
        // border-bottom: 1px solid rgb(157, 166, 173);
        padding: 10px;
        font-size: 16px;
        font-weight: bold;
    }

    .button-user-catalog {
        background-color: #ffffff;
        border: none;
        &:hover {
            background-color: #ffffff;
            border: none;
        }
    }

    .bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
        border-right-color: #ffffff !important;
    }

    .overlay {
        position: absolute; /* Sit on top of the page content */
        width: 100%; /* Full width (cover the whole page) */
        height: 100%; /* Full height (cover the whole page) */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.498039215686275); /* Black background with opacity */
        z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
        cursor: pointer;
    }

.dropdown-menu.show .dropdown-item:not(:last-child) {
  border-bottom: none;
 }

 .rst__lineBlock.rst__lineHalfHorizontalRight.rst__lineFullVertical {
   display: none;
 }

 .rst__lineBlock.rst__lineFullVertical {
   display: none;
 }

 .rst__lineChildren {
   display: none;
 }

 .rst__lineBlock.rst__lineHalfHorizontalRight {
   display: none;
 }

 .rst__row {
   border: none;
 }

 .rst__lineChildren {
   display: none !important;
 }

 .rst__rowWrapper {
   padding: 0 !important;
 }

.drop-item-consent {
  width: 88px;
  height: 16px;
  color: rgb(0, 144, 209);
  font-size: 16px;
  font-family: Roboto-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.2px;
  line-height: 16px;
}
